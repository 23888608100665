import axios from "axios";
import ConfirmationAction from "../../components/ConfirmationAction/ConfirmationAction.vue";


export default {
    name: 'typeformation',
    components: {
        ConfirmationAction
    },

    data() {
        return {
            BASE_URL: this.$root.BASE_URL,
            dialogModal: false,
            isDeveloppeur:this.$store.getters['isAccesForDeveloppeur'],
            crudForm: {
                id: '',
                libeller_type_formation: '',
                active: 0,
                etat_sup_annee_uv: 0,
                list_filiere:[],
            },
            snackBar: {
                color: "grey darken-4",
                icon:'mdi-success',
                position:'top',
                timeout:'',
                title:'',
                text:'',
                visible:false,

            },
            headers: [


                {
                    text: 'Type formation',
                    value: 'libeller_type_formation',
                    class: "text-uppercase text-left",
                    width: '750px'
                },
                {
                    text: 'Mention',
                    value: 'filiere',
                    class: "text-uppercase text-left",
                },
                { text: '-', value: 'actions', sortable: false,width:"110px",
                    align:'center',
                    class: "text-center"},
            ],
            DataTable: {
                fullData: [],
                order_by: "id",
                order: "asc",
                per_page: 10,
                last_page:0,
                page: 1,
                total: 0,
                loading: true,
                recherche_critere: {
                    filtre: '',
                }
            },
            acces:{
                lecture:false,
                create:false,
                edit:false,
                delete:false,
            },
            ELEMENT: this.$store.getters['getAllAcces'][1].module_enfant,
            index_to_delete : '',
        }
    },
    methods: {
        FetchDataTable(){
            let formulaire = new FormData();
            formulaire.append('filtre', this.DataTable.recherche_critere.filtre);
            formulaire.append("order", this.DataTable.order);
            formulaire.append("per_page", this.DataTable.per_page);
            formulaire.append("page", this.DataTable.page);

            this.DataTable.loading = true;
            axios.post(this.BASE_URL + "/typeformation/fetchdata", formulaire).then((response) => {
                this.DataTable.per_page = parseInt(response.data.per_page);
                this.DataTable.fullData = response.data.data;
                this.DataTable.page = parseInt(response.data.page);
                this.DataTable.loading = false;
                this.DataTable.last_page = parseInt(response.data.last_page);

            });
        },

        editRow(data){
            axios.post(this.BASE_URL + "/typeformation/get/"+data.id).then((response) => {
                this.crudForm = response.data[0];
                this.openModal();
            }).catch(error =>{
                console.log(error)
            });
        },
        DeleteRow(data){
            var lien = this.BASE_URL+"/typeformation/delete/"+data.id;
            console.log(lien)
            this.$refs.confirmation.open("Confirmation","Voulez vous vraiment supprimer cette ligne?","100",lien)
        },
        InsertElement() {

            this.crudForm = {
                id: '',
                libeller_type_formation: '',
                active: 0,
                etat_sup_annee_uv: 0,
                list_filiere:[{
                    id:0,
                    etat_sup_filiere:0,
                    id_type_formation:'',
                    nom_mention:'',
                    code_mention:'',
                }],
            };
            this.openModal();
        },
        pushElement(){
            this.crudForm.list_filiere.push({
                id:0,
                etat_sup_filiere:0,
                id_type_formation:'',
                nom_mention:'',
                code_mention:'',
            })
        },
        spliceElement(index,data){
            var lien = this.BASE_URL+"/mention/delete/"+data.id;
            this.$refs.confirmation.open("Confirmation","Voulez vous vraiment supprimer cette mention?","100",lien)
            this.index_to_delete = index;

        },

        deleteMention(cle){
            if (!cle){
                this.crudForm.list_filiere.splice(this.index_to_delete,1);
            }
        },
        saveData() {

            const link = this.crudForm.id == 0 ? this.BASE_URL + '/typeformation/create' :
                this.BASE_URL + '/typeformation/update';
            const data = JSON.stringify(this.crudForm);
            let formulaire = new FormData();
            formulaire.append('data', data)
            axios.post(link, formulaire, {
                'Content-type': 'application/json'
            }).then((response) => {
                console.log(response);
                if (response.data.error==''){
                    this.closeModal();
                    this.FetchDataTable();
                }

                this.snackBar = {
                    text: response.data.error =='' ? response.data.message : response.data.error,
                    color: response.data.error =='' ? "success" : "warning" ,
                    visible: true,
                    timeout:'5000'
                };
            }).catch(error => {
                console.log('erreur', error);

            });
        },

        openModal() {
            this.dialogModal = true;
        },
        closeModal() {
            this.dialogModal = false;
        },


    },
    computed: {},
    watch: {},
    mounted: function () {
        this.FetchDataTable();
        this.ELEMENT.forEach(value =>{
            if (value.appellation==="lsite_type_formation" && value.etat == 1){
                this.acces.lecture = true;
            }

            if (value.appellation==="ajout_type_formation" && value.etat == 1){
                this.acces.create = true;
            }
            if (value.appellation==="modifier_type_formation" && value.etat == 1){
                this.acces.edit = true;
            }

            if (value.appellation==="supprimer_type_formation" && value.etat == 1){
                this.acces.delete = true;
            }
        })
    }
};

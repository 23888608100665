// import axios from "axios";
import ConfirmationAction from "../../../components/ConfirmationAction/ConfirmationAction.vue";
import axiosInstance from "@/AxiosInstance";

export default {
    name: 'InsertNote',
    components: {
        ConfirmationAction
    },

    data() {
        return {
            snackbar: false,
            link_delete:"",
            id_delete: '',
            text: ``,
            ColorNotif:"",
            dialog: false,
            resolve: null,
            reject: null,
            message: null,
            title: null,
            index_delete: '',
            options: {
                color: "rgb(76, 175, 88)",
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },


            BASE_URL: this.$root.BASE_URL,
            isDeveloppeur: this.$store.getters['isAccesForDeveloppeur'],
            dialogModal: false,
            disableCherche:true,
            list_examen: [],
            submit:'',
            snackBar: {
                color: "grey darken-4",
                icon: 'mdi-success',
                position: 'top',
                timeout: '',
                title: '',
                text: '',
                visible: false,

            },
            filiere_liste: [],
            niveau_liste: [],
            id_matiere: '',
            nom_matiere:'',
            remarque:'',
            List_remarque : [{value:'Session Normale'}, {value: 'Repêchage'}],
            form:{
                id: '',
                nom_matiere: '',
                code_matiere: '',
                credit_matiere: '',
                id_ue: '',
                matiere_examen: [{id_matiere: '', nom_matiere_examen: '', coefficient_matiere: ''}],
                list_etudiant: [],
            },

            CrudNote: {
                id: '',
                id_niveau: '',
                id_filiere: '',
                nom_examen: '',
                code_examen: '',
                nom_matiere: '',
                etat_suppression_note: '',
                note_unite_enseignement: {
                    id: '',
                    id_note: '',
                    libeller_unite_enseignement: '',
                    etat_suppression_note_ue: '',
                    etudiant: [],
                    matiere_examen:[{
                        libeller_examen : '',
                        nom_matiere: this.nom_matiere,
                        code_matiere: '',
                        coefficient: '',
                    }],
                }
            },

            acces: {
                lecture: false,
                create: false,
                edit: false,
                delete: false,
            },
            rules: {
                textRequis: [
                    v => !!v || 'CE CHAMP EST REQUIS',
                ],
                listBox: [val => (val || '').length > 0 || 'CE CHAMP EST REQUIS'],
            },
            ListExamen: [],
            ListUE:[],
            ListeMatiere:[],
            AllData:[],
        }
    },
    methods: {
        LoadExamenByNiveau () {
            axiosInstance.get(this.BASE_URL + '/examen/loadbyniveau/' + this.CrudNote.id_niveau).then((response) => {
                this.ListExamen = response.data;
            })
        },

        LoadElement() {
            this.LoadExamenByNiveau();
            this.IsDisabled();
            this.CrudNote.note_unite_enseignement.note_etudiant = [];
            this.CrudNote.nom_examen ='';
            this.CrudNote.note_unite_enseignement.libeller_unite_enseignement ='';
            this.nom_matiere = '';
        },

        LoadUniteEnseignement(){
            this.CrudNote.note_unite_enseignement.note_etudiant = [];
            this.CrudNote.note_unite_enseignement.libeller_unite_enseignement ='';
            this.nom_matiere = '';

            let formulaire = new FormData();
            formulaire.append('nom_examen', this.CrudNote.nom_examen);
            formulaire.append('id_niveau', this.CrudNote.id_niveau);
            axiosInstance.post(this.BASE_URL + '/ue/loadbynomexamen/',formulaire).then((response) => {
                this.ListUE = response.data;
                this.IsDisabled();
            })
        },

        LoadMatiere(){
            this.CrudNote.note_unite_enseignement.note_etudiant = [];
            this.nom_matiere = '';
            let formulaire = new FormData();
            formulaire.append('libeller_ue',this.CrudNote.note_unite_enseignement.libeller_unite_enseignement);
            formulaire.append('nom_examen', this.CrudNote.nom_examen);
            formulaire.append('id_niveau', this.CrudNote.id_niveau);
            axiosInstance.post(this.BASE_URL+'/matiere/loadbyue/',formulaire).then( (response) => {
                this.ListeMatiere = response.data;
                this.IsDisabled();
            })
        },
        selectMatiere(){
            this.IsDisabled();
            this.CrudNote.note_unite_enseignement.note_etudiant = [];
            this.CrudNote.nom_matiere = this.nom_matiere;
        },
        LoadNiveau() {
            this.CrudNote.note_unite_enseignement.note_etudiant = [];
            this.CrudNote.id_niveau= '';
            this.CrudNote.nom_examen ='';
            this.CrudNote.note_unite_enseignement.libeller_unite_enseignement ='';
            this.nom_matiere = '';
            axiosInstance.get(this.BASE_URL + "/niveau/getniveauparfiliere/" + this.CrudNote.id_filiere).then((response) => {
                this.niveau_liste = response.data;
                this.IsDisabled();
            }).catch(error => {
                console.log(error)
            });
        },

        saveData() {

            const link = this.submit == 'insert' ? this.BASE_URL + '/matieresexamennote/create' :
                this.BASE_URL + '/matieresexamennote/update';

            const data = JSON.stringify(this.CrudNote);
            let formulaire = new FormData();
            formulaire.append('data', data)
            axiosInstance.post(link, formulaire, {
                'Content-type': 'application/json'
            }).then((response) => {
                console.log(response);
                if (response.data.error == '') {
                    this.showStudent();
                }

                this.snackBar = {
                    text: response.data.error == '' ? response.data.message : response.data.error,
                    color: response.data.error == '' ? "success" : "warning",
                    visible: true,
                    timeout: '5000'
                };
            }).catch(error => {
                console.log('erreur', error);

            });
        },

        getAllFiliere() {
            axiosInstance.get(this.BASE_URL + "/filiere/getall/").then((response) => {
                this.filiere_liste = response.data;
            }).catch(error => {
                console.log(error)
            });
        },
        pushExamen() {
            const matiere_examen = {
                libeller_examen : 'Note '+(this.CrudNote.note_unite_enseignement.matiere_examen.length + 1),
                nom_matiere: this.nom_matiere,
                code_matiere: '',
                coefficient: '',
            };

            this.CrudNote.note_unite_enseignement.matiere_examen.push(matiere_examen);
            this.CrudNote.note_unite_enseignement.etudiant.forEach(value => {
                value.matiere_examen_note.push({
                    note_etudiant: '',
                    id_etudiant_niveau: value.id,
                    id_matiere_examen: '',
                    libeller_unite_enseignement: this.CrudNote.note_unite_enseignement.libeller_unite_enseignement,
                    nom_matiere: this.nom_matiere
                })
            })
        },
        cancel (){
            this.dialog = false;
        },
        agree(){

            if (parseInt(this.id_delete) > 0){

                axiosInstance.get(this.link_delete).then((response) => {
                    this.snackBar = {
                        text: response.data.error == '' ? response.data.message : response.data.error,
                        color: response.data.error == '' ? "success" : "warning",
                        visible: true,
                        timeout: '5000'
                    };
                    this.cancel();
                }).catch(error => {
                    console.log('erreur', error);

                });
            }
            this.CrudNote.note_unite_enseignement.matiere_examen.splice(this.index_delete,1);
            this.CrudNote.note_unite_enseignement.etudiant.forEach(value => {
                value.matiere_examen_note.splice(this.index_delete,1)
            });
        },
        spliceListe(index,id) {
            this.dialog = true;
            this.title = 'Confirmation';
            this.message = 'Voulez vous vraiment supprimer cet exam?';
            this.link_delete = this.BASE_URL+"/matieresexamennote/deleteelement/"+id;
            this.index_delete = index;
            this.id_delete = id;
        },
        showStudent(){
            let formulaire = new FormData();
            formulaire.append('id_filiere',this.CrudNote.id_filiere);
            formulaire.append('id_niveau',this.CrudNote.id_niveau);
            formulaire.append('nom_examen',this.CrudNote.nom_examen);
            formulaire.append('libeller_unite_enseignement',this.CrudNote.note_unite_enseignement.libeller_unite_enseignement);
            formulaire.append('nom_matiere',this.nom_matiere);
            axiosInstance.post(this.BASE_URL+'/matieresexamennote/getelement',formulaire).then((response)=> {
                this.submit = response.data.type;
                this.list_examen = response.data.list;
                if (response.data.error == '') {
                    if (response.data.type=='update'){
                        this.CrudNote = response.data.allData
                    }else{
                        this.CrudNote.note_unite_enseignement.etudiant = response.data.allData.etudiant;
                        // this.CrudNote.note_unite_enseignement.etudiant.forEach(value => {
                        //     value.matiere_examen_note =[{
                        //         note_etudiant: '',
                        //         id_etudiant_niveau: value.id,
                        //         id_matiere_examen: '',
                        //         libeller_unite_enseignement: this.CrudNote.note_unite_enseignement.libeller_unite_enseignement,
                        //         nom_matiere: this.nom_matiere
                        //     }]
                        // })
                        this.CrudNote.note_unite_enseignement.matiere_examen = response.data.allData.matiere_examen;
                    }
                }else{
                    this.snackBar = {
                        text: response.data.error == '' ? response.data.message : response.data.error,
                        color: response.data.error == '' ? "success" : "warning",
                        visible: true,
                        timeout: '5000'
                    };
                }


            })
        },
        IsDisabled() {
            // this.disableCherche =  (this.CrudNote.id_filiere > 0 && this.CrudNote.id_niveau > 0 && this.CrudNote.id_niveau > 0 && this.CrudNote.nom_examen !== '' && this.CrudNote.libeller_unite_enseignement !== '' && this.nom_matiere !== '') ? false : true;
            this.disableCherche =  (!(this.CrudNote.id_filiere > 0 && this.CrudNote.id_niveau > 0 && this.CrudNote.id_niveau > 0 && this.CrudNote.nom_examen !== '' && this.CrudNote.libeller_unite_enseignement !== '' && this.nom_matiere !== ''));
        }
    },
    computed: {

    },
    watch: {},
    mounted: function () {
        this.getAllFiliere();

    }
};

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueNumeric from 'vue-numeric';
import '@mdi/font/css/materialdesignicons.css';
import '../public/css/style.css';
import { jwtDecode } from "jwt-decode";
import SingIn from './views/SingIn/SingIn.vue';
import store from './store/store';
import axiosInstance from "@/AxiosInstance";

Vue.config.productionTip = false;
Vue.prototype.$store = store;

Vue.use(VueNumeric);

if (process.env.NODE_ENV == 'development') {
    Vue.BASE_URL = Vue.prototype.BASE_URL = '';
} else { // Production
    Vue.BASE_URL = Vue.prototype.BASE_URL = '';
    // Vue.BASE_URL = Vue.prototype.BASE_URL = '/APP/ISSED/';
}

var vm = null;
var token = localStorage.getItem("token");
var user = [];

// Créez une fonction asynchrone pour charger les données avant de monter l'application
async function loadDataAndMountApp() {
    if (token == undefined || token == '' || token == null) {
        vm = new Vue({
            router,
            vuetify,
            render: h => h(SingIn),
        }).$mount('#app');
    } else {
        var users_decoder = jwtDecode(token);
        user = users_decoder[0];
        store.commit('setAccesForDeveloppeur', user.nom_groupe === 'DEVELOPPEUR');
        store.commit('setUsers', user);

        try {
            const response = await axiosInstance.get(Vue.BASE_URL + "/groupeutilisateur/getelement/" + user.id_groupe_utilisateur);
            store.commit('setAllAcces', response.data.groupe_acces);

        } catch (error) {
            console.error("Erreur lors du chargement des données:", error);
        }

        vm = new Vue({
            router,
            vuetify,
            render: h => h(App),
        }).$mount('#app');

        vm.$emit('change-load', 'loaded', user);
    }
}

// Appelez la fonction pour charger les données avant de monter l'application
loadDataAndMountApp();

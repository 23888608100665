import axios from "axios";
import ConfirmationAction from "../../components/ConfirmationAction/ConfirmationAction.vue";


export default {
    name: 'FraisformationParametre',
    components: {
        ConfirmationAction
    },

    data() {
        return {
            BASE_URL: this.$root.BASE_URL,
            isDeveloppeur:this.$store.getters['isAccesForDeveloppeur'],
            dialogModal: false,
            crudForm: {
                id: '',
                id_niveau:'',
                id_filiere:'',
                libeller_param_frais: '',
                id_annee_univ: '',
                etat_sup_param_frais: 0,
                list_detail:[],
            },
            snackBar: {
                color: "grey darken-4",
                icon:'mdi-success',
                position:'top',
                timeout:'',
                title:'',
                text:'',
                visible:false,

            },
            headers: [

                {
                    text: 'A-U',
                    value: 'annee_universitaire',
                    class: "text-uppercase text-left",
                    width: '150px'
                },

                {
                    text: 'Titre',
                    value: 'libeller_param_frais',
                    class: " text-left",
                },
                {
                    text: 'Type Formation',
                    value: 'libeller_type_formation',
                    class: " text-left",
                },
                {
                    text: 'Parcours',
                    value: 'nom_filieres',
                    class: " text-left",
                },
                {
                    text: 'Niveau',
                    value: 'libeller_niveau',
                    class: " text-left",

                },

                { text: '-', value: 'actions', sortable: false,width:"110px",
                    align:'center',
                    class: "text-center"},
            ],
            DataTable: {
                fullData: [],
                order_by: "id",
                order: "asc",
                per_page: 10,
                last_page:0,
                page: 1,
                total: 0,
                loading: true,
                recherche_critere: {
                    filtre: '',
                }
            },
            filiere_liste:[],
            niveau_liste:[],
            id_filiere:null,
            rules: {
                textRequis: [
                    v => !!v || 'CE CHAMP EST REQUIS',
                ],
                listBox: [val => (val || '').length > 0 || 'CE CHAMP EST REQUIS'],
            },
            disable_btn:false,

            acces:{
                lecture:false,
                create:false,
                edit:false,
                delete:false,
            },
            ELEMENT: this.$store.getters['getAllAcces'][1].module_enfant,
        }
    },
    methods: {
        LoadNiveau(){
            axios.get(this.BASE_URL + "/niveau/getniveauparfiliere/"+this.crudForm.id_filiere).then((response) => {
                this.niveau_liste = response.data;
            }).catch(error =>{
                console.log(error)
            });
        },
        getAllFiliere(){
            axios.get(this.BASE_URL + "/filiere/getall/").then((response) => {
                this.filiere_liste = response.data;
            }).catch(error =>{
                console.log(error)
            });
        },
        FetchDataTable(){
            let formulaire = new FormData();
            formulaire.append('filtre', this.DataTable.recherche_critere.filtre);
            formulaire.append("order", this.DataTable.order);
            formulaire.append("per_page", this.DataTable.per_page);
            formulaire.append("page", this.DataTable.page);

            this.DataTable.loading = true;
            axios.post(this.BASE_URL + "/fraisformationparametre/fetchdata", formulaire).then((response) => {
                this.DataTable.per_page = parseInt(response.data.per_page);
                this.DataTable.fullData = response.data.data;
                this.DataTable.page = parseInt(response.data.page);
                this.DataTable.loading = false;
                this.DataTable.last_page = parseInt(response.data.last_page);

            });
        },

        editRow(data){
            axios.post(this.BASE_URL + "/fraisformationparametre/get/"+data.id).then((response) => {
                this.crudForm = response.data[0];
                this.LoadNiveau();
                this.openModal();
            }).catch(error =>{
                console.log(error)
            });
        },
        DeleteRow(data){
            var lien = this.BASE_URL+"/fraisformationparametre/delete/"+data.id;
            console.log(lien)
            this.$refs.confirmation.open("Confirmation","Voulez vous vraiment supprimer cette ligne?","100",lien)
        },
        InsertElement() {

            this.crudForm = {
                id: '',
                id_niveau:'',
                libeller_param_frais: '',
                id_annee_univ: '',
                id_filiere:'',
                etat_sup_param_frais: 0,
                list_detail:[{
                    id:0,
                    id_param_frais:0,
                    libeller_frais:'',
                    code_frais:'',
                    montant_frais:'',
                }],
            };
            this.openModal();
        },
        pushElement(){
            this.crudForm.list_detail.push({
                id:0,
                id_param_frais:0,
                libeller_frais:'',
                montant_frais:'',
                code_frais:'',
            })
        },
        spliceElement(index){
            this.crudForm.list_detail.splice(index,1);
        },


        saveData() {
            this.disable_btn = true;
            const link = this.crudForm.id == 0 ? this.BASE_URL + '/fraisformationparametre/create' :
                this.BASE_URL + '/fraisformationparametre/update';
            const data = JSON.stringify(this.crudForm);
            let formulaire = new FormData();
            formulaire.append('data', data)
            axios.post(link, formulaire, {
                'Content-type': 'application/json'
            }).then((response) => {
                console.log(response);
                this.disable_btn = false;
                if (response.data.error==''){
                    this.closeModal();
                    this.FetchDataTable();
                }

                this.snackBar = {
                    text: response.data.error =='' ? response.data.message : response.data.error,
                    color: response.data.error =='' ? "success" : "warning" ,
                    visible: true,
                    timeout:'5000'
                };
            }).catch(error => {
                console.log('erreur', error);

            });
        },

        openModal() {
            this.dialogModal = true;
        },
        closeModal() {
            this.dialogModal = false;
        },


    },
    computed: {},
    watch: {},
    mounted: function () {
        this.FetchDataTable();
        this.getAllFiliere();
        this.ELEMENT.forEach(value =>{
            if (value.appellation==="cree_frais_formation" && value.etat == 1){
                this.acces.lecture = true;
            }

            if (value.appellation==="ajout_type_formation" && value.etat == 1){
                this.acces.create = true;
            }
            if (value.appellation==="modifier_frais_formation" && value.etat == 1){
                this.acces.edit = true;
            }

            if (value.appellation==="supprimer_frais_formation" && value.etat == 1){
                this.acces.delete = true;
            }
        })
    }
};

<template>
    <div>
        <v-form
                lazy-validation
                ref="form"
                id="formulaire"
                v-on:submit.prevent="saveData"
        >
            <v-col cols="12">
                <vue-editor id="editor"
                            v-model="crudForm.modele_certificat"
                            :editorToolbar="customToolbbar"

                >

                </vue-editor>
                <textarea v-show="false" name="modele_certificat" v-model="crudForm.modele_certificat" id="" cols="30" rows="10"></textarea>
            </v-col>
            <v-col cols="12">
                <v-btn

                        type="submit"
                        width="100%"
                        depressed
                        color="primary"
                >
                    Enregistrer
                </v-btn>
            </v-col>

        </v-form>

        <v-snackbar
                v-model="snackBar.visible"

                :color="snackBar.color"
                :multi-line="snackBar.mode === 'multi-line'"
                :timeout="snackBar.timeout"
                :top="snackBar.position === 'top'">
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackBar.icon }}</v-icon>
                <v-layout column>
                    <div>
                        <strong>{{ snackBar.title }}</strong>
                    </div>
                    <div>{{ snackBar.text }}</div>
                </v-layout>
            </v-layout>
            <v-btn v-if="snackBar.timeout === 0" @click="snackBar.visible = false">
                <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import {VueEditor,Quill} from "vue2-editor";
    import axios from "axios";

    function getFontName(font) {
        return font.toLowerCase().replace(/\s/g, "-");
    }

    // Specify Quill fonts
    const fontList = ['Arial', 'Courier', 'Garamond', 'Tahoma', 'Times New Roman', 'Verdana'];
    const fontNames = fontList.map(font => getFontName(font));
    const fonts = Quill.import('formats/font');
    fonts.whitelist = fontNames;
    Quill.register(fonts, true);

    // Add fonts to CSS style
    let fontStyles = "";
    fontList.forEach(function(font) {
        let fontName = getFontName(font);
        fontStyles += ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" + fontName + "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" + fontName + "]::before {" +
            "content: '" + font + "';" +
            "font-family: '" + font + "', sans-serif;" +
            "}" +
            ".ql-font-" + fontName + "{" +
            " font-family: '" + font + "', sans-serif;" +
            "}";
    });

    const node = document.createElement('style');
    node.innerHTML = fontStyles;
    document.body.appendChild(node);

    export default {
        components:{
            VueEditor
        },
        name: "CertificatScolarite",
        data: function () {
            return{
                customToolbbar:[
                    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                    [{ 'font': fonts.whitelist }],
                    ["bold","italic","underline","strike"],
                    [{ list: "ordered" }, { list: "bullet" },{list: "check"}],
                    [{ align: "" }, { align: "center" },{align: "right"},{align: 'justify'}],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ color: [] }],
                    [{ size: ['8px', '10px', '12px', '13px', '14px', '15px'] }]

                ],
                crudForm:{
                    id:'',
                    modele_certificat:''
                },
                snackBar: {
                    color: "grey darken-4",
                    icon:'mdi-success',
                    position:'top',
                    timeout:'',
                    title:'',
                    text:'',
                    visible:false,

                },
            }
        },
        methods:{
            getModele(id){
                axios.get(this.BASE_URL+'/modelecertificat/getmodele/'+id).then((resutlat)=>{
                    this.crudForm = resutlat.data[0];
                })
            },
            saveData(){
                this.disable_btn = true;
                const link = this.BASE_URL + '/modelecertificat/create'
                const data = JSON.stringify(this.crudForm);
                let formulaire = new FormData();
                formulaire.append('data', data)
                axios.post(link, formulaire, {
                    'Content-type': 'application/json'
                }).then((response) => {
                    console.log(response);
                    this.disable_btn = false;
                    if (response.data.error==''){

                        this.getModele(response.data.id_modele)
                    }

                    this.snackBar = {
                        text: response.data.error =='' ? response.data.message : response.data.error,
                        color: response.data.error =='' ? "success" : "warning" ,
                        visible: true,
                        timeout:'5000'
                    };
                }).catch(error => {
                    console.log('erreur', error);

                });
            }
        },
        created() {
            this.getModele(1);
        }
    }
</script>

<style scoped>

</style>

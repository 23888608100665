import axiosInstance from "@/AxiosInstance";
import ConfirmationAction from "../../../components/ConfirmationAction/ConfirmationAction.vue";

export default {
    name: 'utilisateur',
    components: {
        ConfirmationAction
    },

    data() {
        return {
            disabled_btn:false,
            isDeveloppeur:this.$store.getters['isAccesForDeveloppeur'],
            BASE_URL: this.$root.BASE_URL,
            dialogModal: false,
            tab_groupe_utilisasteur:[],
            crudForm: {
                id: '',
                nom_utilisateur: '',
                id_groupe_utilisateur: 0,
                login: 0,
                mdp: '',
                mdp1: '',
                etat_suppression: 0,
            },
            snackBar: {
                color: "grey darken-4",
                icon:'mdi-success',
                position:'top',
                timeout:'',
                title:'',
                text:'',
                visible:false,

            },
            headers: [

                {
                    text: 'Groupe',
                    value: 'nom_groupe',
                    class: "text-uppercase text-left",
                },
                {
                    text: 'Nom',
                    value: 'nom_utilisateur',
                    class: "text-uppercase text-left",
                },
                {
                    text: 'Login',
                    value: 'login',
                    class: "text-uppercase text-left",
                },

                { text: '-', value: 'actions', sortable: false,width:"110px",
                    align:'center',
                    class: "text-center"},
            ],
            DataTable: {
                fullData: [],
                order_by: "id",
                order: "asc",
                per_page: 10,
                last_page:0,
                page: 1,
                total: 0,
                loading: true,
                recherche_critere: {
                    filtre: '',
                }
            },
            acces:{
                lecture:false,
                create:false,
                edit:false,
                delete:false,
            },
            // ELEMENT: this.$store.getters['getAllAcces'][3].module_enfant,
            ELEMENT: this.$store.getters['getAllAcces'][3].module_enfant,
        }
    },
    methods: {
        getAllGroupeUtilisateur(){
            axiosInstance.get(this.BASE_URL+'/groupeutilisateur/getall').then((resultat)=>{
                this.tab_groupe_utilisasteur = resultat.data;
            })
        },
        FetchDataTable(){
            let formulaire = new FormData();
            formulaire.append('filtre', this.DataTable.recherche_critere.filtre);
            formulaire.append("order", this.DataTable.order);
            formulaire.append("per_page", this.DataTable.per_page);
            formulaire.append("page", this.DataTable.page);

            this.DataTable.loading = true;
            axiosInstance.post(this.BASE_URL + "/utilisateur/fetchdata", formulaire).then((response) => {
                this.DataTable.per_page = parseInt(response.data.per_page);
                this.DataTable.fullData = response.data.data;
                this.DataTable.page = parseInt(response.data.page);
                this.DataTable.loading = false;
                this.DataTable.last_page = parseInt(response.data.last_page);

            });
        },

        editRow(data){
            axiosInstance.post(this.BASE_URL + "/utilisateur/get/"+data.id).then((response) => {
                if (response.data.error==''){
                    this.openModal();
                    this.crudForm = response.data.data[0]
                }else{
                    this.snackBar = {
                        text: response.data.error =='' ? response.data.message : response.data.error,
                        color: response.data.error =='' ? "success" : "warning" ,
                        visible: true,
                        timeout:'5000'
                    };
                }
            }).catch(error =>{
                console.log(error)
            });
        },
        DeleteRow(data){
            var lien = this.BASE_URL+"/utilisateur/delete/"+data.id;
            console.log(lien)
            this.$refs.confirmation.open("Confirmation","Voulez vous vraiment supprimer cette ligne?","100",lien)
        },
        InsertElement() {

            this.crudForm = {
                id: '',
                nom_utilisateur: '',
                id_groupe_utilisateur: 0,
                login: '',
                mdp:'',
                mdp1:'',
                etat_suppression: 0,
            };
            this.openModal();
        },
        saveData() {

            this.disabled_btn = true;

            const link = this.crudForm.id == 0 ? this.BASE_URL + '/utilisateur/create' :
                this.BASE_URL + '/utilisateur/update';
            const data = JSON.stringify(this.crudForm);
            let formulaire = new FormData();
            formulaire.append('data', data)
            axiosInstance.post(link, formulaire, {
                'Content-type': 'application/json'
            }).then((response) => {
                this.disabled_btn = false;
                console.log(response);
                if (response.data.error==''){
                    this.closeModal();
                    this.FetchDataTable();
                }

                this.snackBar = {
                    text: response.data.error =='' ? response.data.message : response.data.error,
                    color: response.data.error =='' ? "success" : "warning" ,
                    visible: true,
                    timeout:'5000'
                };
            }).catch(error => {
                console.log('erreur', error);

            });
        },

        openModal() {
            this.dialogModal = true;
        },
        closeModal() {
            this.dialogModal = false;
        },


    },
    computed: {},
    watch: {},
    mounted: function () {
        this.getAllGroupeUtilisateur();
        this.FetchDataTable();
        this.ELEMENT.forEach(value =>{
            if (value.appellation==="liste_utilisateur" && value.etat == 1){
                this.acces.lecture = true;
            }
            if (value.appellation==="Supprimer_utilisateur" && value.etat == 1){
                this.acces.delete = true;
            }
            if (value.appellation==="Modifier_utilisateur" && value.etat == 1){
                this.acces.edit = true;
            }
            if (value.appellation==="ajout_utilisateur" && value.etat == 1){
                this.acces.create = true;
            }


        })
    }
};

import axios from "axios";
import ConfirmationAction from "../../../components/ConfirmationAction/ConfirmationAction.vue";
import CompoentFiltre from "../../../components/CompoentFiltre/CompoentFiltre";
import DatePicker from "../../../components/DatePicker/DatePicker.vue";


const now = new Date();
const year = now.getFullYear();
const month = (now.getMonth() + 1).toString().padStart(2, '0');
const day = now.getDate().toString().padStart(2, '0');
const dateNow =  day+'/'+month+'/'+year;
console.log(dateNow)

export default {
    name: 'FraisformationHistorique',
    components: {
        ConfirmationAction,
        CompoentFiltre,
        DatePicker
    },

    data() {
        return {
            BASE_URL: this.$root.BASE_URL,
            dialogModal: false,
            ListedesFormation:[],
            isDeveloppeur:this.$store.getters['isAccesForDeveloppeur'],
            img_default:this.BASE_URL+'/public/assets/img/icon_studen.png',
            snackBar: {
                color: "grey darken-4",
                icon:'mdi-success',
                position:'top',
                timeout:'',
                title:'',
                text:'',
                visible:false,

            },
            headers: [
                {
                    text: 'A-U',
                    value: 'annee_universitaire',
                    class: "text-uppercase text-left",
                },
                {
                    text: 'Date',
                    value: 'date_paiement',
                    class: "text-left",
                },
                {
                    text: 'N° Matricule',
                    value: 'numero_matricule',
                    class: "text-left",
                },
                {
                    text: 'Référence',
                    value: 'reference_paiement',
                    class: "text-left",
                },
                {
                    text: 'FRAIS',
                    value: 'code_frais',
                    class: "text-left",
                },
                {
                    text: 'Montant',
                    value: 'montant_payer',
                    class: "text-right",
                    cellClass:'text-right'
                },

                {
                    text: 'remarque',
                    value: 'commentaire',
                    class: "text-left",
                },
                {
                    text: 'Nom',
                    value: 'nom_etudiant',
                    class: "text-left",
                },
                {
                    text: 'Prénom(s)',
                    value: 'prenom_etudiant',
                    class: "text-left",
                },


                {
                    text: 'Type Formation',
                    value: 'libeller_type_formation',
                    class: "text-left",
                },
                {
                    text: 'Mention',
                    value: 'nom_mention',
                    class: "text-left",
                },
                {
                    text: 'Parcours',
                    value: 'nom_filieres',
                    class: "text-left",
                },

                {
                    text: 'Niveau',
                    value: 'libeller_niveau',
                    class: "text-left",
                },
                {
                    text: 'Utilisateur',
                    value: 'nom_utilisateur',
                    class: "text-left",
                },


                {   text: '-',
                    value: 'actions', sortable: false,width:"110px",
                    align:'center',
                    class: "text-center other-text fixed_column_right",
                    cellClass: "text-center other-text fixed_column_right",
                },
            ],
            DataTable: {
                fullData: [],
                order_by: "id",
                order: "asc",
                per_page: 10,
                last_page:0,
                page: 1,
                total: 0,
                loading: true,
                recherche_critere: {
                    filtre: '',
                    libeller_type_formation: '',
                    nom_filieres: '',
                    libeller_niveau: '',
                    date_debut:'',
                    date_fin:'',
                }
            },
            Filtre:[],
            tab_typeFormation:[],
            tab_filiere:[],
            tab_niveau:[],
            ELEMENT: this.$store.getters['getAllAcces'][0].module_enfant,
            acces:{
                lecture:false,
                create:false,
                edit:false,
                delete:false,
            }
        }
    },
    methods: {
        openModalFiltre(){
            this.$refs.filter.openModalFilter();
        },
        getListypeFormation(){
            axios.get(this.BASE_URL+"/typeformation/getall").then((resultat)=>{
                this.tab_typeFormation = resultat.data;
                this.InitElementFiltre(this.tab_typeFormation,'type_formation');
            })
        },
        getListeFiliere(){
            axios.get(this.BASE_URL+"/filiere/getall/").then((resultat)=>{
                this.tab_filiere = resultat.data;
                this.InitElementFiltre(this.tab_filiere,'filiere');
                this.getListeNiveau();
            })
        },

        getListeNiveau(){
            axios.get(this.BASE_URL+"/niveau/getallniveaudistinct/").then((resultat)=>{
                this.tab_niveau = resultat.data;
                this.InitElementFiltre(this.tab_niveau,'niveau');
            })
        },

        InitElementFiltre(data,type){

            if (type =='type_formation'){
                this.Filtre.push({
                    title: 'Type de Formation',
                    dataSearch: data,
                    item_text: 'libeller_type_formation',
                    item_value:'id',
                    modelValue:''
                })
            }
            if (type=='filiere'){
                this.Filtre.push({
                    title: 'Parcours',
                    dataSearch: data,
                    item_text: 'nom_filieres',
                    item_value:'id',
                    modelValue:''
                })
            }
            if (type=='niveau'){
                this.Filtre.push({
                    title: 'Niveau',
                    dataSearch: data,
                    item_text: 'libeller_niveau',
                    item_value:'libeller_niveau',
                    modelValue:''
                })
            }
        },



        FetchDataTable(){
            let formulaire = new FormData();
            formulaire.append('filtre', this.DataTable.recherche_critere.filtre);
            formulaire.append('libeller_niveau', this.DataTable.recherche_critere.libeller_niveau);
            formulaire.append('nom_filieres', this.DataTable.recherche_critere.nom_filieres);
            formulaire.append('date_debut', this.DataTable.recherche_critere.date_debut);
            formulaire.append('date_fin', this.DataTable.recherche_critere.date_fin);
            formulaire.append('libeller_type_formation', this.DataTable.recherche_critere.libeller_type_formation);
            formulaire.append("order", this.DataTable.order);
            formulaire.append("per_page", this.DataTable.per_page);
            formulaire.append("page", this.DataTable.page);

            this.DataTable.loading = true;
            axios.post(this.BASE_URL + "/etudiantfraisformation/fetchdata", formulaire).then((response) => {
                this.DataTable.per_page = parseInt(response.data.per_page);
                this.DataTable.fullData = response.data.data;
                this.DataTable.page = parseInt(response.data.page);
                this.DataTable.loading = false;
                this.DataTable.last_page = parseInt(response.data.last_page);

            });
        },



        DeleteRow(data){
            var lien = this.BASE_URL+"/etudiantfraisformation/annulerpaiement/"+data.id;
            this.$refs.confirmation.open("Confirmation","Êtes-vous sure d'annuler cette paiement?","100",lien)
        },



    },
    computed: {},
    watch: {},
    mounted: function () {
        this.FetchDataTable();
        this.getListypeFormation();
        this.getListeFiliere();
        console.log(this.ELEMENT)
        this.ELEMENT.forEach(value =>{
            if (value.appellation==="historique_frais_formation" && value.etat == 1){
                this.acces.lecture = true;
            }

            if (value.appellation==="supprimer_frais_etudiant" && value.etat == 1){
                this.acces.delete = true;
            }

        })
    }
};

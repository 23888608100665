import { render, staticRenderFns } from "./MatiereNote.vue?vue&type=template&id=0b7286cb&scoped=true"
import script from "./MatiereNote.js?vue&type=script&lang=js&external"
export * from "./MatiereNote.js?vue&type=script&lang=js&external"
import style0 from "./MatiereNote.vue?vue&type=style&index=0&id=0b7286cb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b7286cb",
  null
  
)

export default component.exports
<template>
    <v-menu
            :ref="refElement"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"

    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                    dense
                    outlined
                    clearable
                    hide-details
                    v-model="timeFormatted"
                    :label="label"
                    hint="HH:mm"
                    :class="class_name"
                    persistent-hint
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    v-bind="attrs"
                    @blur="time = parseTime(timeFormatted)"
                    v-on="on"
                    :name="name"
                    style="padding: 0 !important;"
            ></v-text-field>
            <!-- readonly -->
        </template>
        <v-time-picker
                color="green lighten-1"
                v-model="time"
                @input="menu1 = false"
        ></v-time-picker>
    </v-menu>
</template>

<script>
    export default {
        props: {
            is_default: {
                type: Boolean,
                default: false,
            },
            value: {
                type: String,
                default: "",
                required: false,
            },
            label: {
                type: String,
                default: "",
                required: false,
            },
            refElement: {
                type: String,
                default: "",
                required: false,
            },
            name: {
                type: String,
                default: "",
                required: false,
            },
            class_name: {
                type: String,
                default: "margin-3",
            },
        },
        data() {
            return {
                menu1: false,
                menu2: false,
                time: "",
                timeFormatted: "",
            };
        },
        computed: {
            computedTimeFormatted() {
                return this.formatTime(this.time);
            },
        },

        watch: {
            time(val) {
                val;
                this.timeFormatted = this.formatTime(this.time);
                this.$emit("input", this.timeFormatted);
            },
            value(val) {
                this.time = this.parseTime(val);
            },
        },

        methods: {
            formatTime(time) {
                if (!time) return null;

                const [hour, minute] = time.split(":");
                return `${hour}:${minute}`;
            },
            parseTime(time) {
                if (!time) return null;

                const [hour, minute] = time.split(":");
                return `${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`;
            },

            setDefaultTime() {
                const now = new Date();
                const hour = String(now.getHours()).padStart(2, "0");
                const minute = String(now.getMinutes()).padStart(2, "0");

                this.time = `${hour}:${minute}`;
                this.timeFormatted = this.formatTime(this.time);
            },
        },
        mounted: function () {
            if (this.is_default) {
                this.setDefaultTime();
            }
        },
    };
</script>

import { render, staticRenderFns } from "./MenuEmploi.vue?vue&type=template&id=be5d9000&scoped=true"
import script from "./MenuEmploi.vue?vue&type=script&lang=js"
export * from "./MenuEmploi.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be5d9000",
  null
  
)

export default component.exports
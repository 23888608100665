<template>
    <v-row justify="space-around">
        <v-col cols="auto">
            <v-dialog
                    v-model="dialog"
                    transition="dialog-bottom-transition"
                    :max-width="width"
                    persistent
            >
                <template v-slot:default="dialog">
                    <v-card>
                        <v-toolbar
                                class="elevation-0"
                                color="blue-grey darken-2"
                                dark
                        ><h2>{{titre}}</h2>
                        </v-toolbar>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col
                                            cols="12"
                                    >
                                        <div v-for="(e,index) in Filtre" :key="index">
                                            <v-text-field
                                                    v-show="!Array.isArray(e.dataSearch)"
                                                    :label="e.title"
                                                    v-model="e.modelValue"
                                                    outlined
                                                    required
                                                    dense
                                                    class="text-capitalize my-3"
                                                    hide-details
                                                    clearable
                                            ></v-text-field>

                                            <v-autocomplete
                                                    v-show="Array.isArray(e.dataSearch)"
                                                    :items="e.dataSearch"
                                                    v-model="e.modelValue"
                                                    outlined
                                                    clearable
                                                    class="my-3"
                                                    :item-text="e.item_text"
                                                    :item-value="e.item_value"
                                                    dense
                                                    :label="e.title"
                                                    hide-details
                                            >
                                            </v-autocomplete>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-container>
                                <v-row>
                                    <v-col cols="6">
                                        <v-btn
                                                width="100%"
                                                depressed
                                                color="primary"
                                                @click="rechercher"
                                        >
                                            rechercher
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-btn
                                                width="100%"
                                                color="error"
                                                depressed
                                                @click="dialog.value = false"
                                        >Fermer
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>



                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "CompoentFiltre",
        props: {
            Filtre:{type: Array},
            width: {type: String},
        },
        data: () => ({
            dialog: false,
            titre: 'Filtre',
        }),
        methods: {
            openModalFilter() {
                this.dialog = true;
            },

            rechercher() {
                this.Filtre.forEach(value => {
                    if (Object.keys(this.$parent.DataTable.recherche_critere).includes(value.item_text) ){
                        this.$parent.DataTable.recherche_critere[value.item_text] = value.modelValue;
                    }
                })
                this.$parent.FetchDataTable();
            }
        },
        created() {
            console.log(this._props)
        },
        computed: {},
    }
</script>

<style scoped>

</style>

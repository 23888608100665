// import axios from "axios";
import ConfirmationAction from "../../../components/ConfirmationAction/ConfirmationAction.vue";
import axiosInstance from "@/AxiosInstance";

export default {
    name: 'InsertNote',
    components: {
        ConfirmationAction
    },

    data() {
        return {
            BASE_URL: this.$root.BASE_URL,
            isDeveloppeur: this.$store.getters['isAccesForDeveloppeur'],
            List_remarque : [{value:'Session Normale'}, {value: 'Repêchage'}, {value: 'Général'}],
            dialogModal: false,
            disableCherche:true,
            ETAT_NOTE: [{value: 0, libelle: 'AFFICHER LE NOTE'}, {value: 1, libelle: 'NE PAS AFFICHER LE NOTE'}],
            submit:'',
            snackBar: {
                color: "grey darken-4",
                icon: 'mdi-success',
                position: 'top',
                timeout: '',
                title: '',
                text: '',
                visible: false,

            },
            filiere_liste: [],
            niveau_liste: [],
            id_matiere: '',
            nom_matiere:'',
            type_examen:'Session Normale',
            CrudNote: {
                id: '',
                id_niveau: '',
                id_filiere: '',
                nom_examen: '',
                code_examen: '',
                etat_note: 0,
                type_examen:'',
                etat_suppression_note: '',
                note_unite_enseignement: {
                    id: '',
                    id_note: '',
                    libeller_unite_enseignement: '',
                    etat_suppression_note_ue: '',
                }
            },

            acces: {
                lecture: false,
                create: false,
                edit: false,
                delete: false,
            },
            rules: {
                textRequis: [
                    v => !!v || 'CE CHAMP EST REQUIS',
                ],
                listBox: [val => (val || '').length > 0 || 'CE CHAMP EST REQUIS'],
            },
            ListExamen: [],
            ListUE:[],
            ListeMatiere:[],
            AllData:[],
            PDF_URL:'',
        }
    },
    methods: {
        LoadExamenByNiveau () {
            axiosInstance.get(this.BASE_URL + '/examen/loadbyniveau/' + this.CrudNote.id_niveau).then((response) => {
                this.ListExamen = response.data;
            })
        },

        LoadElement() {
            this.LoadExamenByNiveau();
            this.IsDisabled();
            this.CrudNote.note_unite_enseignement.note_etudiant = [];
            this.CrudNote.nom_examen ='';
            this.CrudNote.note_unite_enseignement.libeller_unite_enseignement ='';
            this.nom_matiere = '';
        },

        LoadUniteEnseignement(){
            this.CrudNote.note_unite_enseignement.note_etudiant = [];
            this.CrudNote.note_unite_enseignement.libeller_unite_enseignement ='';
            this.nom_matiere = '';

            let formulaire = new FormData();
            formulaire.append('nom_examen', this.CrudNote.nom_examen);
            formulaire.append('id_niveau', this.CrudNote.id_niveau);
            axiosInstance.post(this.BASE_URL + '/ue/loadbynomexamen/',formulaire).then((response) => {
                this.ListUE = response.data;
                this.IsDisabled();
            })
        },

        LoadMatiere(){
            this.CrudNote.note_unite_enseignement.note_etudiant = [];
            this.nom_matiere = '';
            let formulaire = new FormData();
            formulaire.append('libeller_ue',this.CrudNote.note_unite_enseignement.libeller_unite_enseignement);
            formulaire.append('nom_examen', this.CrudNote.nom_examen);
            formulaire.append('id_niveau', this.CrudNote.id_niveau);
            axiosInstance.post(this.BASE_URL+'/matiere/loadbyue/',formulaire).then( (response) => {
                this.ListeMatiere = response.data;
                this.IsDisabled();
            })
        },
        selectMatiere(){
            this.IsDisabled();
            this.CrudNote.note_unite_enseignement.note_etudiant = [];
        },
        LoadNiveau() {
            this.CrudNote.note_unite_enseignement.note_etudiant = [];
            this.CrudNote.id_niveau= '';
            this.CrudNote.nom_examen ='';
            this.CrudNote.note_unite_enseignement.libeller_unite_enseignement ='';
            this.nom_matiere = '';
            axiosInstance.get(this.BASE_URL + "/niveau/getniveauparfiliere/" + this.CrudNote.id_filiere).then((response) => {
                this.niveau_liste = response.data;
                this.IsDisabled();
            }).catch(error => {
                console.log(error)
            });
        },

        saveData() {

            const link = this.submit == 'insert' ? this.BASE_URL + '/note/create' :
                this.BASE_URL + '/note/update';
            const data = JSON.stringify(this.CrudNote);
            let formulaire = new FormData();
            formulaire.append('data', data)
            axiosInstance.post(link, formulaire, {
                'Content-type': 'application/json'
            }).then((response) => {
                console.log(response);
                if (response.data.error == '') {

                    console.log('d')
                }

                this.snackBar = {
                    text: response.data.error == '' ? response.data.message : response.data.error,
                    color: response.data.error == '' ? "success" : "warning",
                    visible: true,
                    timeout: '5000'
                };
            }).catch(error => {
                console.log('erreur', error);

            });
        },

        getAllFiliere() {
            axiosInstance.get(this.BASE_URL + "/filiere/getall/").then((response) => {
                this.filiere_liste = response.data;
            }).catch(error => {
                console.log(error)
            });
        },
        exportToExcel(){
            const params = new URLSearchParams();
            params.append('id_filiere',this.CrudNote.id_filiere);
            params.append('id_niveau',this.CrudNote.id_niveau);
            params.append('nom_examen',this.CrudNote.nom_examen);
            params.append('nom_matiere',this.nom_matiere);
            params.append('type_examen',this.CrudNote.type_examen);
            params.append('etat_note',this.CrudNote.etat_note);
            window.open(`${this.BASE_URL}/note/exportexcelnotebysymestre?${params.toString()}`,'_blank');
        },
        showStudent(){
            this.PDF_URL = '';
            let formulaire = new FormData();
            formulaire.append('id_filiere',this.CrudNote.id_filiere);
            formulaire.append('id_niveau',this.CrudNote.id_niveau);
            formulaire.append('nom_examen',this.CrudNote.nom_examen);
            formulaire.append('nom_matiere',this.nom_matiere);
            formulaire.append('type_examen',this.CrudNote.type_examen);
            formulaire.append('etat_note',this.CrudNote.etat_note);

            axiosInstance.post(this.BASE_URL+'/note/getnotetoprintbysemestre',formulaire).then((response)=> {
                this.submit = response.data.type;
                if (response.data.error == '') {
                    this.AllData = response.data.data
                    /*if (response.data.type=='update'){
                        this.CrudNote = response.data.allData
                    }else{
                        this.CrudNote.note_unite_enseignement.note_etudiant = response.data.allData;
                    }*/
                    // this.PDF_URL = response.data.data.pdfUrl;
                    // console.log(response.data.data.pdfUrl);

                }else{
                    this.snackBar = {
                        text: response.data.error == '' ? response.data.message : response.data.error,
                        color: response.data.error == '' ? "success" : "warning",
                        visible: true,
                        timeout: '5000'
                    };
                }


            })
        },
        IsDisabled() {
            console.log((this.CrudNote.id_filiere > 0 && this.CrudNote.id_niveau > 0 && this.CrudNote.id_niveau > 0 && this.CrudNote.nom_examen !== ''  ))
            let valeur = (this.CrudNote.id_filiere > 0 && this.CrudNote.id_niveau > 0 && this.CrudNote.id_niveau > 0 && this.CrudNote.nom_examen !== '' ) ? false : true;
            this.disableCherche =  valeur;
        }
    },
    computed: {

    },
    watch: {},
    mounted: function () {
        this.getAllFiliere();

    }
};

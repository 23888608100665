// import axios from "axios";
import ConfirmationAction from "../../../components/ConfirmationAction/ConfirmationAction.vue";
import axiosInstance from "@/AxiosInstance";
import ShowPdf from "@/components/ShowPdf/ShowPdf.vue";

export default {
    name: 'InsertNote',
    components: {
        ConfirmationAction,
        ShowPdf
    },

    data() {
        return {
            moyenne_ecrit: 0,
            coef_moyenne_ecrit: 0,
            mini_memoire: 0,
            coef_mini_memoire: 0,
            note_finale: 0,
            coef_note_finale: 0,
            link_pdf: '',
            title_pdf: '',
            BASE_URL: this.$root.BASE_URL,
            isDeveloppeur: this.$store.getters['isAccesForDeveloppeur'],
            List_remarque : [{value:'Session Normale'}, {value: 'Repêchage'}, {value: 'Général'}],
            dialogModal: false,
            disableCherche:true,
            submit:'',
            crudDeliberation: {
                id: '',
                id_annee_universitaire: '',
                moyenne_deliberation: '',
                id_niveau: '',
            },
            snackBar: {
                color: "grey darken-4",
                icon: 'mdi-success',
                position: 'top',
                timeout: '',
                title: '',
                text: '',
                visible: false,

            },
            filiere_liste: [],
            niveau_liste: [],
            id_matiere: '',
            nom_matiere:'',
            type_examen:'Session Normale',
            CrudNote: {
                id: '',
                id_niveau: '',
                id_filiere: '',
                nom_examen: '',
                code_examen: '',
                type_examen:'',
                etat_suppression_note: '',
                note_unite_enseignement: {
                    id: '',
                    id_note: '',
                    libeller_unite_enseignement: '',
                    etat_suppression_note_ue: '',
                }
            },

            acces: {
                lecture: false,
                create: false,
                edit: false,
                delete: false,
            },
            rules: {
                textRequis: [
                    v => !!v || 'CE CHAMP EST REQUIS',
                ],
                listBox: [val => (val || '').length > 0 || 'CE CHAMP EST REQUIS'],
            },
            ListExamen: [],
            ListUE:[],
            ListeMatiere:[],
            AllData:[],
            PDF_URL:'',
        }
    },
    methods: {
        LoadExamenByNiveau () {
            axiosInstance.get(this.BASE_URL + '/examen/loadbyniveau/' + this.CrudNote.id_niveau).then((response) => {
                this.ListExamen = response.data;
            })
        },

        LoadElement() {
            this.LoadExamenByNiveau();
            this.IsDisabled();
            this.CrudNote.note_unite_enseignement.note_etudiant = [];
            this.CrudNote.nom_examen ='';
            this.CrudNote.note_unite_enseignement.libeller_unite_enseignement ='';
            this.nom_matiere = '';
        },

        LoadUniteEnseignement(){
            this.CrudNote.note_unite_enseignement.note_etudiant = [];
            this.CrudNote.note_unite_enseignement.libeller_unite_enseignement ='';
            this.nom_matiere = '';

            let formulaire = new FormData();
            formulaire.append('nom_examen', this.CrudNote.nom_examen);
            formulaire.append('id_niveau', this.CrudNote.id_niveau);
            axiosInstance.post(this.BASE_URL + '/ue/loadbynomexamen/',formulaire).then((response) => {
                this.ListUE = response.data;
                this.IsDisabled();
            })
        },

        LoadMatiere(){
            this.CrudNote.note_unite_enseignement.note_etudiant = [];
            this.nom_matiere = '';
            let formulaire = new FormData();
            formulaire.append('libeller_ue',this.CrudNote.note_unite_enseignement.libeller_unite_enseignement);
            formulaire.append('nom_examen', this.CrudNote.nom_examen);
            formulaire.append('id_niveau', this.CrudNote.id_niveau);
            axiosInstance.post(this.BASE_URL+'/matiere/loadbyue/',formulaire).then( (response) => {
                this.ListeMatiere = response.data;
                this.IsDisabled();
            })
        },
        selectMatiere(){
            this.IsDisabled();
            this.CrudNote.note_unite_enseignement.note_etudiant = [];
        },
        LoadNiveau() {
            this.CrudNote.note_unite_enseignement.note_etudiant = [];
            this.CrudNote.id_niveau= '';
            this.CrudNote.nom_examen ='';
            this.CrudNote.note_unite_enseignement.libeller_unite_enseignement ='';
            this.nom_matiere = '';
            axiosInstance.get(this.BASE_URL + "/niveau/getniveauparfiliere/" + this.CrudNote.id_filiere).then((response) => {
                this.niveau_liste = response.data;
                this.IsDisabled();
            }).catch(error => {
                console.log(error)
            });
        },

        getMoyenne(id){
            axiosInstance.get(this.BASE_URL + "/moyennedeliberation/get/"+id).then((response) => {
                this.crudDeliberation = response.data[0];
            }).catch(error => {
                console.log(error)
            });
        },

        getMoyennebyniveau(){
            axiosInstance.get(this.BASE_URL + "/moyennedeliberation/getbyniveau/"+this.CrudNote.id_niveau).then((response) => {
                if (response.data.length > 0){
                    this.crudDeliberation = response.data[0];
                }else{
                    this.initDeliberation()
                }

            }).catch(error => {
                console.log(error)
            });
        },

        saveData() {

            const link = this.BASE_URL + '/moyennedeliberation/create';
            this.crudDeliberation.id_niveau = this.CrudNote.id_niveau;
            const data = JSON.stringify(this.crudDeliberation);
            let formulaire = new FormData();
            formulaire.append('data', data)
            axiosInstance.post(link, formulaire, {
                'Content-type': 'application/json'
            }).then((response) => {
                console.log(response);
                if (response.data.error == '') {
                    this.closeModal();
                    this.getMoyenne(response.data.id);
                    this.showStudent();
                }

                this.snackBar = {
                    text: response.data.error == '' ? response.data.message : response.data.error,
                    color: response.data.error == '' ? "success" : "warning",
                    visible: true,
                    timeout: '5000'
                };
            }).catch(error => {
                console.log('erreur', error);

            });
        },

        getAllFiliere() {
            axiosInstance.get(this.BASE_URL + "/filiere/getall/").then((response) => {
                this.filiere_liste = response.data;
            }).catch(error => {
                console.log(error)
            });
        },
        calculerSommeMoyennes(semestre) {
            let somme = 0;
            semestre.unite_enseignement.forEach(ue => {
                let moyenne = parseFloat(ue.total_note_ue) / parseFloat(ue.total_credit_unite_enseignement);
                somme += moyenne;
            });
            return this.formatNumber(somme); // Formater à deux décimales
        },
        formatNumber(value) {
            // Convertir en nombre décimal
            let number = parseFloat(value);

            // Vérifier si le nombre est un nombre valide
            if (!isNaN(number)) {
                // Formater avec deux chiffres après la virgule
                let formattedNumber = number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                // Si le nombre est inférieur à 10 et positif, ajouter un zéro avant
                if (number >= 0 && number < 10) {
                    formattedNumber = '0' + formattedNumber;
                }

                return formattedNumber;
            } else {
                // Retourner la valeur d'origine si elle n'est pas valide
                return value;
            }
        },

        openModal(){
            this.dialogModal = true;
            this.getMoyennebyniveau();
        },
        closeModal () {
            this.dialogModal = false;
        },
        initDeliberation (){
            this.crudDeliberation = {
                id: '',
                id_annee_universitaire: '',
                moyenne_deliberation: '',
                id_niveau: '',
            }
        },
        printRelever(){
            let formulaire = new FormData();
            formulaire.append('id_niveau',this.CrudNote.id_niveau);
            formulaire.append('id_filiere',this.CrudNote.id_filiere);
            axiosInstance.post(this.BASE_URL+'/note/printrelevernote',formulaire).then((response)=> {
                this.link_pdf = response.data.pdfUrl;
                this.$refs.print_etudiant.openModalPrint();
                this.title_pdf = "Liste relevé de note";
            })
        },
        printEtudiantAdmis() {

            let formulaire = new FormData();
            formulaire.append('id_niveau',this.CrudNote.id_niveau);
            axiosInstance.post(this.BASE_URL+'/note/listetudiantadmis',formulaire).then((response)=> {
                this.link_pdf = response.data.pdfUrl;
                this.title_pdf = "List Etudiant Admis";
                this.$refs.print_etudiant.openModalPrint();
            })

        },
        showStudent(){
            this.PDF_URL = '';
            let formulaire = new FormData();
            formulaire.append('id_filiere',this.CrudNote.id_filiere);
            formulaire.append('id_niveau',this.CrudNote.id_niveau);

            axiosInstance.post(this.BASE_URL+'/note/getrelevernote',formulaire).then((response)=> {
                this.submit = response.data.type;
                if (response.data.error == '') {
                    /*if (response.data.type=='update'){
                        this.CrudNote = response.data.allData
                    }else{
                        this.CrudNote.note_unite_enseignement.note_etudiant = response.data.allData;
                    }*/
                    this.PDF_URL = response.data.data;

                }else{
                    this.snackBar = {
                        text: response.data.error == '' ? response.data.message : response.data.error,
                        color: response.data.error == '' ? "success" : "warning",
                        visible: true,
                        timeout: '5000'
                    };
                }


            })
        },
        IsDisabled() {
            let valeur = (this.CrudNote.id_filiere > 0 && this.CrudNote.id_niveau > 0 ) ? false : true;
            this.disableCherche =  valeur;
        }
    },
    computed: {

    },
    watch: {},
    mounted: function () {
        this.getAllFiliere();

    }
};

import axios from "axios";
import ConfirmationAction from "../../../components/ConfirmationAction/ConfirmationAction.vue";


export default {
    name: 'FraisformationParametre',
    components: {
        ConfirmationAction
    },

    data() {
        return {
            BASE_URL: this.$root.BASE_URL,
            isDeveloppeur: this.$store.getters['isAccesForDeveloppeur'],
            dialogModal: false,
            tab_search:[{value_search:null}],
            ListEnseignant:[],
            crudForm: {
                id: '',
                nom_examen: '',
                code_examen:'',
                id_niveau:'',
                id_filiere:'',
                UE: [{
                    id: '',
                    id_examen:'',
                    libeller_ue:'',
                    ListeMatiere:[{
                        id:'',
                        id_ue:'',
                        nom_matiere:'',
                        code_matiere:'',
                        credit_matiere:0,
                        id_enseignant:'',
                        nom_enseignant:'',
                        nom_complet:'',
                    }]
                }]
            },
            titleModal:'Nouveau Examen',
            snackBar: {
                color: "grey darken-4",
                icon: 'mdi-success',
                position: 'top',
                timeout: '',
                title: '',
                text: '',
                visible: false,

            },
            headers: [
                {
                    text: 'Type Formation',
                    value: 'libeller_type_formation',
                    class: " text-left",
                },
                {
                    text: 'Parcours',
                    value: 'nom_filieres',
                    class: " text-left",
                },
                {
                    text: 'Niveau',
                    value: 'libeller_niveau',
                    class: " text-left",

                },
                {
                    text: 'Nom examen',
                    value: 'nom_examen',
                    class: " text-left",

                },
                {
                    text: 'Code',
                    value: 'code_examen',
                    class: " text-left",

                },
                {
                    text: 'Element constitutif',
                    value: 'ue_element',
                    class: " text-left",

                },

                {
                    text: '-', value: 'actions', sortable: false, width: "110px",
                    align: 'center',
                    class: "text-center"
                },
            ],
            DataTable: {
                fullData: [],
                order_by: "id",
                order: "asc",
                per_page: 10,
                last_page: 0,
                page: 1,
                total: 0,
                loading: true,
                recherche_critere: {
                    filtre: '',
                }
            },
            filiere_liste: [],
            niveau_liste: [],
            id_filiere: null,
            rules: {
                textRequis: [
                    v => !!v || 'CE CHAMP EST REQUIS',
                ],
                listBox: [val => (val || '').length > 0 || 'CE CHAMP EST REQUIS'],
            },
            disable_btn: false,

            acces: {
                lecture: false,
                create: false,
                edit: false,
                delete: false,
            },
            ELEMENT: this.$store.getters['getAllAcces'][1].module_enfant,
            element_delete: '',
            index_to_delete: '',
            index_to_delete_matiere: '',
        }
    },
    methods: {
        getEnseignant() {
            axios.get(this.BASE_URL + "/enseignant/getall/").then((response) => {
                this.ListEnseignant = response.data;
            }).catch(error => {
                console.log(error)
            });
        },
        LoadNiveau() {
            axios.get(this.BASE_URL + "/niveau/getniveauparfiliere/" + this.crudForm.id_filiere).then((response) => {
                this.niveau_liste = response.data;
            }).catch(error => {
                console.log(error)
            });
        },
        getAllFiliere() {
            axios.get(this.BASE_URL + "/filiere/getall/").then((response) => {
                this.filiere_liste = response.data;
            }).catch(error => {
                console.log(error)
            });
        },
        FetchDataTable() {
            let formulaire = new FormData();
            formulaire.append('filtre', this.DataTable.recherche_critere.filtre);
            formulaire.append("order", this.DataTable.order);
            formulaire.append("per_page", this.DataTable.per_page);
            formulaire.append("page", this.DataTable.page);

            this.DataTable.loading = true;
            axios.post(this.BASE_URL + "/examen/fetchdata", formulaire).then((response) => {
                this.DataTable.per_page = parseInt(response.data.per_page);
                this.DataTable.fullData = response.data.data;
                this.DataTable.page = parseInt(response.data.page);
                this.DataTable.loading = false;
                this.DataTable.last_page = parseInt(response.data.last_page);

            });
        },

        editRow(data) {

            axios.post(this.BASE_URL + "/examen/get/" + data.id).then((response) => {
                this.crudForm = response.data[0];
                this.LoadNiveau();
                this.openModal();
            }).catch(error => {
                console.log(error)
            });
        },
        DeleteRow(data) {
            var lien = this.BASE_URL + "/examen/delete/" + data.id;
            console.log(lien)
            this.$refs.confirmation.open("Confirmation", "Voulez vous vraiment supprimer cette ligne?", "100", lien)
        },
        confirmDeleteUE(){
            this.crudForm.UE.splice( this.index_to_delete,1);
        },

        splicehUE(index, data){
            this.index_to_delete = index;
            if (parseInt(data.id) > 0 ){
                var lien = this.BASE_URL+"/ue/delete/"+data.id;
                this.$refs.confirmation.open("Confirmation","Voulez vous vraiment supprimer cet UE?","100",lien)
            }else{
                this.confirmDeleteUE()
            }

            this.element_delete = 'ue'

        },
        deleteMention(cle){
            if (!cle && this.element_delete =='ue'){
                this.confirmDeleteUE()
            }
            if (!cle && this.element_delete =='matiere'){
                this.confirmDeleteMatiere()
            }
        },

        pushElement(index) {
            this.tab_search.push({
                value_search:null
            });
            this.crudForm.UE[index].ListeMatiere.push({
                id:'',
                id_ue:'',
                nom_matiere:'',
                code_matiere:'',
                credit_matiere:0,
                id_enseignant:'',
                nom_complet:'',
                nom_enseignant:''
            })
        },
        confirmDeleteMatiere() {
            this.crudForm.UE[this.index_to_delete].ListeMatiere.splice(this.index_to_delete_matiere, 1);
            // this.pushElement.splice(index,1);
        },

        spliceElement(index_ue,index_matiere,data){
            this.index_to_delete = index_ue;
            this.index_to_delete_matiere = index_matiere;

            if (parseInt(data.id) > 0 ){
                var lien = this.BASE_URL+"/matiere/delete/"+data.id;
                this.$refs.confirmation.open("Confirmation","Voulez vous vraiment supprimer ce matière?","100",lien)
            }else{
                this.confirmDeleteMatiere()
            }

            this.element_delete = 'matiere'

        },


        pushUE(){
            this.crudForm.UE.push({
                id: '',
                id_examen:'',
                libeller_ue:'',
                ListeMatiere:[{
                    id:'',
                    id_ue:'',
                    nom_matiere:'',
                    code_matiere:'',
                    credit_matiere:0,
                    id_enseignant:'',
                    nom_enseignant:'',
                    nom_complet:'',
                }]
            })
        },
        InsertElement() {
            this.titleModal ='Nouveau Examen';
            this.crudForm = {
                id: '',
                nom_examen: '',
                code_examen:'',
                id_niveau:'',
                id_filiere:'',
                UE: [{
                    id: '',
                    id_examen:'',
                    libeller_ue:'',
                    ListeMatiere:[{
                        id:'',
                        id_ue:'',
                        nom_matiere:'',
                        code_matiere:'',
                        credit_matiere:0,
                        id_enseignant:'',
                        nom_enseignant:'',
                        nom_complet:'',
                    }]
                }]
            };
            this.openModal();
        },



        saveData() {
            this.disable_btn = true;
            const link = this.crudForm.id == 0 ? this.BASE_URL + '/examen/create' :
                this.BASE_URL + '/examen/update';
            const data = JSON.stringify(this.crudForm);
            let formulaire = new FormData();
            formulaire.append('data', data)
            axios.post(link, formulaire, {
                'Content-type': 'application/json'
            }).then((response) => {
                console.log(response);
                this.disable_btn = false;
                if (response.data.error == '') {
                    this.closeModal();
                    this.FetchDataTable();
                }

                this.snackBar = {
                    text: response.data.error == '' ? response.data.message : response.data.error,
                    color: response.data.error == '' ? "success" : "warning",
                    visible: true,
                    timeout: '5000'
                };
            }).catch(error => {
                console.log('erreur', error);

            });
        },

        openModal() {
            this.dialogModal = true;
        },
        closeModal() {
            this.dialogModal = false;
        },

        handleSearchChange(newValue) {

            let matricule = newValue == null ? '' : newValue;
            if (matricule !== '') {
                axios.get(this.BASE_URL + "/enseignant/listenomenseignant/" + matricule)
                    .then((response) => {
                        this.ListEnseignant = response.data;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },

        /*selectEtudiant(){
            axios.get(this.BASE_URL+"/etudiant/getinfobynom/"+this.crudForm.numero_matricule).then((resultat)=>{
                this.crudForm = resultat.data[0];
                this.crudForm.id_niveau ='';
                this.crudForm.id_type_formation ='';
                this.crudForm.id_filiere='';
                this.img_default = (this.crudForm.photo_etudiant !='' && this.crudForm.photo_etudiant != null) ? this.BASE_URL+"/assets/images/etudiant/"+this.crudForm.photo_etudiant :
                    this.BASE_URL+"/public/assets/img/avatar-diplome.png";
            })
        },*/
    },
    computed: {},
    watch: {

    },
    mounted: function () {
        this.FetchDataTable();
        this.getAllFiliere();
        this.getEnseignant();
        this.ELEMENT.forEach(value => {
            if (value.appellation === "cree_frais_formation" && value.etat == 1) {
                this.acces.lecture = true;
            }

            if (value.appellation === "ajout_type_formation" && value.etat == 1) {
                this.acces.create = true;
            }
            if (value.appellation === "modifier_frais_formation" && value.etat == 1) {
                this.acces.edit = true;
            }

            if (value.appellation === "supprimer_frais_formation" && value.etat == 1) {
                this.acces.delete = true;
            }
        })
    }
};

import axios from "axios";

export default {
    name: 'EtatFraisFormation',
    props:{

    },
    data() {
        return {
            BASE_URL: this.$root.BASE_URL,
            Data:[],
            dialogModal:false,
        }
    },
    methods: {

        getEtatFraisFormation(id_etudiant_niveau){
            axios.get(this.BASE_URL+'/etudiantfraisformation/getetatfrais/'+id_etudiant_niveau).then((response) => {
                this.Data = response.data;
                this.openModal();
            } )
        },

        openModal() {
            this.dialogModal = true;
        },
        closeModal() {
            this.dialogModal = false;
        },

    },
    computed: {},
    watch: {},
    mounted: function () {

    }
};

import axios from "axios";
import ConfirmationAction from "../../../components/ConfirmationAction/ConfirmationAction.vue";
import DatePicker from "../../../components/DatePicker/DatePicker.vue";
import axiosInstance from "@/AxiosInstance";

export default {
    name: 'EtudiantInscription',
    components: {
        ConfirmationAction,
        DatePicker
    },

    data() {
        return {
            BASE_URL: this.$root.BASE_URL,
            dialogModal: false,
            sexeList: ['Masculin', 'Féminin'],
            rules: {
                textRequis: [
                    v => !!v || 'CE CHAMP EST REQUIS',
                ],
                listBox: [val => (val || '').length > 0 || 'CE CHAMP EST REQUIS'],
            },
            crudForm: {
                id: '',
                id_annee_universitaire: '',
                date_inscription_en: '',
                date_inscription_en_formatted: '',
                id_etudiant_niveau:this.$route.params.id_etudiant_niveau ?? 0,
                mail_etudiant: '',
                numero_matricule: '',
                nom_etudiant:'',
                prenom_etudiant:'',
                sexe_etudiant:'',
                adresse_etudiant:'',
                date_naissance_etudiant:'',
                date_naissance_etudiant_formatted:'',
                lieu_naissance_etudiant:'',
                telephone_etudiant:'',
                photo_etudiant:'',
                etat_suppression_etudiant:0,
                id_niveau:'',
                id_etudiant:'',
                etat_sup_etudiant_niveau:0,
                id_type_formation:'',
                id_filiere:'',
                id_mention:'',
            },
            snackBar: {
                color: "grey darken-4",
                icon:'mdi-success',
                position:'top',
                timeout:'',
                mode:'multi-line',
                title:'',
                text:'',
                visible:false,

            },
            tab_typeFormation:[],
            tab_filiere:[],
            tab_mention:[],
            tab_numeroMatricule:[],
            tab_niveau:[],
            show_loading: false,
            img_default:this.BASE_URL+'/public/assets/img/avatar-diplome.png',
            filesPhoto: null,
            disabled_btn:false,
            search_Matricule:'',
            descriptionLimit: 60,
            entries: [],
            model: null,
            search: null
        }
    },

    methods: {
        getMention(){
            axiosInstance.get(this.BASE_URL+'/mention/getpartypeformation/'+this.crudForm.id_type_formation).then(response => {
                this.tab_mention = response.data
            })
        },
        selectEtudiant(){
            axios.get(this.BASE_URL+"/etudiant/getelementnumeromatricule/"+this.crudForm.numero_matricule).then((resultat)=>{
                this.crudForm = resultat.data[0];
                this.crudForm.id_niveau ='';
                this.crudForm.id_type_formation ='';
                this.crudForm.id_filiere='';
                this.img_default = (this.crudForm.photo_etudiant !='' && this.crudForm.photo_etudiant != null) ? this.BASE_URL+"/assets/images/etudiant/"+this.crudForm.photo_etudiant :
                    this.BASE_URL+"/public/assets/img/avatar-diplome.png";
            })
        },
        openFilePhoto() {
            this.$refs.fileInput.$refs.input.click();
        },
        getListypeFormation(){
            axios.get(this.BASE_URL+"/typeformation/getall").then((resultat)=>{
                this.tab_typeFormation = resultat.data;
            })
        },
        getListeFiliere(){
            axios.get(this.BASE_URL+"/filiere/gettoformation/"+this.crudForm.id_type_formation).then((resultat)=>{
                this.tab_filiere = resultat.data;
            })
        },

        getListeNiveau(){
            axios.get(this.BASE_URL+"/niveau/getniveauparfiliere/"+this.crudForm.id_filiere).then((resultat)=>{
                this.tab_niveau = resultat.data;
            })
        },

        getEtudiant(id_etudiant){
            axios.get(this.BASE_URL+"/etudiantniveau/get/"+id_etudiant).then((resultat)=>{
                this.crudForm = resultat.data[0];

            })
        },

        saveData() {
            this.disabled_btn = true;
            const link = this.BASE_URL + '/etudiantniveau/reinscription' ;

            const data = JSON.stringify(this.crudForm);
            let formulaire = new FormData();
            formulaire.append('data', data)
            axios.post(link, formulaire, {
                'Content-type': 'application/json'
            }).then((response) => {
                this.disabled_btn = false;
                console.log(response);
                if (response.data.error==''){
                   console.log('dd')
                }

                this.snackBar = {
                    text: response.data.error =='' ? response.data.message : response.data.error,
                    color: response.data.error =='' ? "success" : "warning" ,
                    visible: true,
                    timeout:'5000',
                    mode:'multi-line',
                };
            }).catch(error => {
                console.log('erreur', error);

            });
        },
        uploadPhoto(event) {
            console.log(event)
            const URL = this.BASE_URL + '/etudiant/fileupload';

            // Accédez au fichier lui-même à partir de this.$refs
            const fileInput = this.$refs.fileInput;
            const file = fileInput.$refs.input.files[0];

            console.log('file-input', fileInput);
            console.log('file', file);
            if (!file) {
                console.error("Aucun fichier sélectionné.");
                return;
            }

            this.show_loading = true;

            const data = new FormData();
            data.append('file-input', file);

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };

            axios.post(URL, data, config)
                .then(response => {
                    console.log('Fichier upload response > ', response);
                    this.img_default = this.BASE_URL + '/folder/temp/' + response.data.upload_data.file_name;
                    this.crudForm.photo_etudiant = response.data.upload_data.file_name;
                    this.show_loading = false;
                })
                .catch(error => {
                    console.error(error);
                    this.snackBar = {
                        message: error,
                        color: "red darken-2",
                        status: true
                    };
                    this.show_loading = false;
                });
        },
        createImage(file) {
            var reader = new FileReader();
            reader.onload = (e) => {
                this.img_default = e.target.result;
            };
            reader.readAsDataURL(file);
        },

    },
    computed: {


    },
    watch: {
        search (val) {
            console.log(val)
            let matricule =  val == null ? '' : val;
            if (matricule != ''){
                axios.get(this.BASE_URL+"/etudiantniveau/listenumeromatricule/"+matricule).then((response)=>{
                    this.tab_numeroMatricule = response.data;
                })
            }
        }
    },
    mounted: function () {
        this.getListypeFormation();
    },
    created:function () {

    }
};

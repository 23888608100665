<template>
    <v-container fluid="">
        <v-row>
            <v-col cols="12">
                <BarNavigation :title="titre" :data="ListeMenu" @modal-toggle="toggleModal"
                               @menu-toggle="toggleMenu"></BarNavigation>

                <router-view>
                </router-view>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import BarNavigation from "@/components/BarNavigation/BarNavigation";

    export default {
        name: "MenuEtudiant",
        components: {
            BarNavigation
        },
        data() {
            return {
                titre: 'Emploi du temps',
                ListeMenu: [
                    {
                        menu: 'Listes', link: '/emploi_du_temps/liste', isShow: false,
                    },

                ]
            }
        },
        methods: {
            AfficheMenuw() {
                console.log('dfsd')
            },
            toggleModal(value) {
                this.$emit('open-modal', value);
            },
            toggleMenu(value) {
                this.$emit("toggle-menu");
                this.$emit('change-page', value);
            },
            VerifToShow() {
                let menu_etudiant = this.$store.getters['getAllAcces'][7].module_enfant;
                if (!this.$store.getters['isAccesForDeveloppeur']) {
                    menu_etudiant.forEach((value) => {
                        if (value.appellation == 'list_emploi_du_temps' && value.etat == 1) {
                            this.ListeMenu[0].isShow = true;
                            return false;
                        }
                    })
                }else{
                    this.ListeMenu.forEach(value => {
                        value.isShow = true;
                    })
                }


                console.log('module_enfant ', menu_etudiant)
            },
        },
        mounted() {
            setTimeout(() => {
                this.VerifToShow();
            }, 750)
        }

    }
</script>

<style scoped>

</style>

<script>
export default {
    name: "ShowPdf",
    props: {
        title: {
            type: String,
        },
        link_pdf: {
            type: String,
            required: true,

        }
    },
    data() {
        return{
            dialogModalPrint: false,
            src_pdf: this.link_pdf
        }
    },
    methods: {
        closeModalPrint() {
            this.dialogModalPrint = false;
            this.src_pdf = '';
        },
        openModalPrint(){
            this.dialogModalPrint = true;
        }
    },

}
</script>

<template>
    <v-dialog
        v-model="dialogModalPrint"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >

        <v-card>
            <v-toolbar
                color="#4caf50"
                dark
                class="pl-3"
            >

                <v-toolbar-title>{{ title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn
                        icon
                        dark
                        @click="closeModalPrint"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <iframe id="iframe_note" :src="link_pdf" :base-url="link_pdf" width="100%" style=" height: calc(125vh - 150px); border: none; background: #666;"></iframe>

            </v-card-text>

        </v-card>
    </v-dialog></template>

<style scoped>

</style>
<template>
    <v-container fluid="">
        <v-row>
            <v-col cols="12">
                <BarNavigation :title="titre" :data="ListeMenu" @modal-toggle="toggleModal" @menu-toggle="toggleMenu"></BarNavigation>

                <router-view  >
                </router-view>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import BarNavigation from "@/components/BarNavigation/BarNavigation";

    export default {
        name: "MenuUtilisateur",
        components:{
            BarNavigation
        },
        data()  {
            return{
                titre:'Module enseignant',
                ListeMenu:[
                    {
                        menu:'Liste Enseignant', link:'/enseignant/liste',isShow:false,
                    }
                ]
            }
        },
        methods:{
            AfficheMenuw(){
                console.log('qui est ton parent',this.$parent)
            },
            toggleModal(value){
                this.$emit('open-modal', value);
            },
            toggleMenu(value) {
                this.$emit("toggle-menu");
                this.$emit('change-page', value);
            },
            VerifToShow(){
                let menu = this.$store.getters['getAllAcces'][5].module_enfant;
                if (!this.$store.getters['isAccesForDeveloppeur']) {
                    menu.forEach((value)=>{
                        if (value.appellation =="liste_enseignant" && value.etat ==1){
                            this.ListeMenu[0].isShow = true;
                            return false;
                        }
                    })
                }else{
                    this.ListeMenu.forEach(value => {
                        value.isShow = true;
                    })
                }

                console.log('module_enfant ', menu)
            },
        },
        mounted() {
            // setTimeout(()=>{
            this.VerifToShow();
            // },750)
        }

    }
</script>

<style scoped>

</style>

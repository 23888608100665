<template>
    <div>
        <v-snackbar
                v-model="snackbar"
                :color="ColorNotif"
        >
            {{ text }}

            <template v-slot:action="{ attrs }">
                <v-icon v-bind="attrs"
                        @click="snackbar = false"></v-icon>

            </template>
        </v-snackbar>




        <v-dialog
                v-model="dialog"
                :max-width="options.width"
                :style="{ zIndex: options.zIndex }"
                @keydown.esc="cancel"
                persistent
        >
            <v-card>
                <v-toolbar dark :color="options.color" dense flat>
                    <v-toolbar-title  class="text-body-2 font-weight-bold white--text">
                        {{ title }}
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text
                        v-show="!!message"
                        class="pa-4 black--text"
                        v-html="message"
                ></v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                            v-if="!options.noconfirm"
                            color="grey"
                            text
                            class="body-2 font-weight-bold"
                            @click.native="cancel"
                    >Cancel</v-btn
                    >
                    <v-btn
                            color="primary"
                            class="body-2 font-weight-bold"
                            outlined
                            @click.native="agree"
                    >OK</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
    import axiosInstance from "@/AxiosInstance";

    export default {
        name: "ConfirmationAction",

        data() {
            return {
                snackbar: false,
                link_delete:"",
                text: ``,
                ColorNotif:"",
                dialog: false,
                resolve: null,
                reject: null,
                message: null,
                title: null,
                options: {
                    color: "rgb(76, 175, 88)",
                    width: 400,
                    zIndex: 200,
                    noconfirm: false,
                },
            };
        },
        methods: {
            open(title, message, options,link) {
                console.log(link)
                this.link_delete = link;
                this.dialog = true;
                this.title = title;
                this.message = message;
                this.options = Object.assign(this.options, options);
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            agree() {
                var that = this;
                var cle = true
                axiosInstance.get( that.link_delete).then((response) => {
                    // console.log(response.data[0]
                    if (response.data.error){
                        that.snackbar = true;
                        that.text = response.data.error;
                        that.ColorNotif = "#FF555550"
                        cle = true
                    }else{
                        that.snackbar = true;
                        that.text = response.data.message;
                        that.ColorNotif = "#28a745"
                        that.$parent.FetchDataTable();
                        cle = false
                    }
                    this.$emit('confirmationMessage', cle);
                    that.resolve(false);
                    that.dialog = false;
                });
                console.log(this.link_delete)

            },
            cancel() {
                this.resolve(false);
                this.dialog = false;
            },
        }
    }
</script>

<style scoped>

</style>

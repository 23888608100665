import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import ParametreApplication from '../views/ParametreApplication/ParametreApplication.vue'
import AnneeUniversitaire from '../views/AnneeUniversitaire/AnneeUniversitaire.vue'
import AnneeUniversitaires from '../views/AnneeUniversitaire/AnneeUniversitaire.vue'
import TypeFormation from '../views/TypeFormation/TypeFormation.vue'
import MentionList from '../views/MentionList/MentionList.vue'
import ParcoursList from '../views/ParcoursList/ParcoursList.vue'
// import FiliereListe from '../views/FiliereListe/FiliereListe.vue'
import NiveauListe from '../views/NiveauListe/NiveauListe.vue'
import FraisformationParametre from '../views/FraisformationParametre/FraisformationParametre.vue'
import GestionEtudiant from '../views/GestionEtudiant/MenuEtudiant/MenuEtudiant.vue'
import ListeEtudiant from '../views/GestionEtudiant/ListeEtudiant/ListeEtudiant.vue'
import ListeEtudiants from '../views/GestionEtudiant/ListeEtudiant/ListeEtudiant.vue'
import CertificatEdutiant from '../views/GestionEtudiant/CertificatEdutiant/CertificatEdutiant.vue'
import EtudiantInscription from '../views/GestionEtudiant/EtudiantInscription/EtudiantInscription.vue'
import EtudiantReinscription from '../views/GestionEtudiant/EtudiantReinscription/EtudiantReinscription.vue'
import ListeFraisFormation from '../views/GestionEtudiant/ListeFraisFormation/ListeFraisFormation.vue'
import CaisseListe from '../views/GestionCaisse/CaisseListe/CaisseListe.vue'
import CaisseParametre from '../views/GestionCaisse/CaisseParametre/CaisseParametre.vue'
import CaisseFlux from '../views/GestionCaisse/CaisseFlux/CaisseFlux.vue'
import CertificatScolarite from '../views/CertificatScolarite/CertificatScolarite.vue'
import ParametreEnseignant from '../views/ModuleEnseignant/ParametreEnseignant/ParametreEnseignant.vue'
import EnseignantListe from '../views/ModuleEnseignant/EnseignantListe/EnseignantListe.vue'
import EnseignantEnregistrement from '../views/ModuleEnseignant/EnseignantEnregistrement/EnseignantEnregistrement.vue'
import MenuUtilisateur from '../views/GestionUtilisateur/MenuUtilisateur/MenuUtilisateur.vue'
import ListeUtilisateur from '../views/GestionUtilisateur/ListeUtilisateur/ListeUtilisateur.vue'
import GroupeUtilisateur from '../views/GestionUtilisateur/GroupeUtilisateur/GroupeUtilisateur.vue'
import GroupeUtilisateurs from '../views/GestionUtilisateur/GroupeUtilisateur/GroupeUtilisateur.vue'
import MenuInstitut from '../views/InfoInstitut/MenuInstitut/MenuInstitut.vue'
import InformationInstitut from '../views/InfoInstitut/InformationInstitut/InformationInstitut.vue'
import InformationInstituts from '../views/InfoInstitut/InformationInstitut/InformationInstitut.vue'
import FraisformationHistorique from '../views/GestionEtudiant/FraisformationHistorique/FraisformationHistorique.vue'
import ExamenList from '../views/ParametreS/ExamenList/ExamenList.vue'
import MenuNote from '../views/GestionNote/MenuNote/MenuNote.vue'
import PrintnoteSemestre from '../views/GestionNote/PrintnoteSemestre/PrintnoteSemestre.vue'
import ListeNotes from '../views/GestionNote/ListeNotes/ListeNotes.vue'
import MenuEmploi from '../views/GestionEmploiDuTemp/MenuEmploi/MenuEmploi.vue'
import EmploiDuTemps from '../views/GestionEmploiDuTemp/EmploiDuTemp/EmploiDuTemp.vue'
import InsertNote from '../views/GestionNote/InsertNote/InsertNote.vue'
import NoteMemoire from '../views/GestionNote/NoteMemoir/NoteMemoir.vue'
import PrintNote from '../views/GestionNote/PrintNote/PrintNote.vue'
import ReleveNote from '../views/GestionNote/ReleveNote/ReleveNote.vue'
import AppreciationMention from '../views/ParametreApplication/AppreciationMention/AppreciationMention.vue'
import MatiereNote from "@/views/GestionNote/MatiereNote/MatiereNote.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'parametres',
        component: ParametreApplication,
        props: true,
    },
    {
        path: '/parametres',
        name: 'ParametreApplication',
        component: ParametreApplication,
        props: true,
        children: [
            {
                path: '',
                name: 'AnneeUniversitaires',
                component: AnneeUniversitaires,
                props: true,
            },
            {
                path: 'annee-universitaire',
                name: 'AnneeUniversitaire',
                component: AnneeUniversitaire,
                props: true,
            },
            {
                path: 'formation-type',
                name: 'TypeFormation',
                component: TypeFormation,
                props: true,
            },
            {
                path: 'mention',
                name: 'Mention',
                component: MentionList,
                props: true,
            },
            {
                path: 'parcours',
                name: 'ParcoursList',
                component: ParcoursList,
                props: true,
            },
            // {
            //     path: 'filiere',
            //     name: 'FiliereListe',
            //     component: FiliereListe,
            //     props: true,
            // },
            {
                path: 'niveau',
                name: 'NiveauListe',
                component: NiveauListe,
                props: true,
            },
            {
                path: 'frais-formation',
                name: 'FraisformationParametre',
                component: FraisformationParametre,
                props: true,
            },
            {
                path: 'certificat',
                name: 'CertificatScolarite',
                component: CertificatScolarite,
                props: true,
            },
            {
                path: 'examen',
                name: 'ExamenList',
                component: ExamenList,
                props: true,
            },
            {
                path: 'appreciation',
                name: 'AppreciationMention',
                component: AppreciationMention,
                props: true,
            },
        ]
    },

    {
        path: '/gestionnote',
        name: 'MenuNote',
        component: MenuNote,
        props: true,
        children: [
            {
                path: '',
                name: 'MatiereNote',
                component: MatiereNote,
                props: true,
            },
            {
                path: 'matiere-note',
                name: 'MatiereNotes',
                component: MatiereNote,
                props: true,
            },
            {
                path: 'insert-note',
                name: 'InsertNote',
                component: InsertNote,
                props: true,
            },
            {
                path: 'insert-note-memoire',
                name: 'NoteMemoire',
                component: NoteMemoire,
                props: true,
            },
            {
                path: 'imprimer-note',
                name: 'PrintNote',
                component: PrintNote,
                props: true,
            },
            {
                path: 'imprime-note/semestre',
                name: 'PrintnoteSemestre',
                component: PrintnoteSemestre,
                props: true,
            },
            {
                path: 'releve-note',
                name: 'ReleveNote',
                component: ReleveNote,
                props: true,
            },
            {
                path: 'list-note',
                name: 'ListeNotes',
                component: ListeNotes,
                props: true,
            },

        ]
    },
    {
        path: '/emploi_du_temps',
        name: 'EmploiDuTemps',
        component: MenuEmploi,
        props: true,
        children: [
            {
                path: '',
                name: 'EmploiDuTemps',
                component: EmploiDuTemps,
                props: true,
            },
            {
                path: 'liste',
                name: 'EmploiDuTemps',
                component: EmploiDuTemps,
                props: true,
            },

        ]
    },

    {
        path: '/gestionetudiant',
        name: 'GestionEtudiant',
        component: GestionEtudiant,
        props: true,
        children: [
            {
                path: '',
                name: 'ListeEtudiant',
                component: ListeEtudiant,
                props: true,
            },
            {
                path: 'liste-etudiant',
                name: 'ListeEtudiants',
                component: ListeEtudiants,
                props: true,
            },
            {
                path: 'inscrire-etudiant',
                name: 'EtudiantInscription',
                component: EtudiantInscription,
                props: true,
            },
            {
                path: 'update-etudiant/:id_etudiant_niveau',
                name: 'EtudiantInscription',
                component: EtudiantInscription,
                props: true,
            },

            {
                path: 'frais-formation',
                name: 'ListeFraisFormation',
                component: ListeFraisFormation,
                props: true,
            },
            {
                path: 'frais-formation-hitsorique',
                name: 'FraisformationHistorique',
                component: FraisformationHistorique,
                props: true,
            },
            {
                path: 'certificat',
                name: 'CertificatEdutiant',
                component: CertificatEdutiant,
                props: true,
            },
            {
                path: 'reinscription',
                name: 'EtudiantReinscription',
                component: EtudiantReinscription,
            },
            /*{
                path: 'frais-formation',
                name: 'FraisformationParametre',
                component: FraisformationParametre,
            },*/
        ]
    },
    {
        path: '/gestionutilisateur',
        name: 'MenuUtilisateur',
        component: MenuUtilisateur,
        props: true,
        children: [
            {
                path: '',
                name: 'GroupeUtilisateur',
                component: GroupeUtilisateur,
                props: true,
            },
            {
                path: 'groupe-utilisateur',
                name: 'GroupeUtilisateur',
                component: GroupeUtilisateurs,
                props: true,
            },
            {
                path: 'liste-utilisateur',
                name: 'ListeUtilisateur',
                component: ListeUtilisateur,
                props: true,
            },

        ]
    },

    {
        path: '/caisse',
        name: 'CaisseParametre',
        component: CaisseParametre,
        props: true,
        children: [
            {
                path: '',
                name: 'CaisseListe',
                component: CaisseListe,
                props: true,
            },
            {
                path: 'liste',
                name: 'CaisseListe',
                component: CaisseListe,
                props: true,
            },
            {
                path: 'flux',
                name: 'CaisseFlux',
                component: CaisseFlux,
                props: true,
            },
        ]
    },
    {
        path: '/info-institut',
        name: 'MenuInstitut',
        component: MenuInstitut,
        props: true,
        children: [
            {
                path: '',
                name: 'InformationInstitut',
                component: InformationInstitut,
                props: true,
            },
            {
                path: 'institut',
                name: 'InformationInstituts',
                component: InformationInstituts,
                props: true,
            }
        ]
    },

    {
        path: '/enseignant',
        name: 'ParametreEnseignant',
        component: ParametreEnseignant,
        props: true,
        children: [
            {
                path: '',
                name: 'EnseignantListe',
                component: EnseignantListe,
                props: true,
            },
            {
                path: 'liste',
                name: 'EnseignantListe',
                component: EnseignantListe,
                props: true,
            },
            {
                path: 'enregistrement',
                name: 'EnseignantEnregistrement',
                component: EnseignantEnregistrement,
                props: true,
            }, {
                path: 'update-enseignant/:id_enseignant',
                name: 'EnseignantEnregistrement',
                component: EnseignantEnregistrement,
                props: true,
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router


export default {
    state: {
        accesForDeveloppeur: false,
        Users:{},
        AllAcces:[]
    },
    mutations: {
        setAccesForDeveloppeur(state, value) {
            state.accesForDeveloppeur = value
            console.log('accesForDeveloppeur updated:', state.accesForDeveloppeur);

        },
        setUsers(state,users){
            state.Users = users;
            console.log('Users updated:', state.Users);

        },
        setAllAcces(state,acces){
            state.AllAcces = acces;
        }
    },
    getters: {
        isAccesForDeveloppeur: state => state.accesForDeveloppeur,
        getUsers: state => state.Users,
        getAllAcces: state => state.AllAcces,
    }
}

<template>
    <v-container fluid="">
        <v-row>
            <v-col cols="12">
                <BarNavigation :title="titre" :data="ListeMenu" @modal-toggle="toggleModal" @menu-toggle="toggleMenu"></BarNavigation>

                <router-view  >
                </router-view>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import BarNavigation from "@/components/BarNavigation/BarNavigation";

    export default {
        name: "ParametreApplication",
        components:{
            BarNavigation
        },
        data()  {
            return{
                titre:'Paramètres',
                ListeMenu:[
                    {
                        menu:'Année Universitaire', link:'/parametres/annee-universitaire',isShow:false,
                    },
                    {
                        menu:'Type Formation', link:'/parametres/formation-type',isShow:false,
                    },
                    {
                        menu:'Mention', link:'/parametres/mention',isShow:false,
                    },
                    {
                        menu:'Parcours', link:'/parametres/parcours',isShow:false,
                    },
                    {
                        menu:'Niveau', link:'/parametres/niveau',isShow:false,
                    },
                    {
                        menu:'Frais Formation', link:'/parametres/frais-formation',isShow:true,
                    },
                    {
                        menu:'Examen', link:'/parametres/examen',isShow:true,
                    },
                    {
                        menu:'Appreciation', link:'/parametres/appreciation',isShow:true,
                    },


                  /*  {
                        menu:'Certificat', link:'/parametres/certificat'
                    },*/
                ]
            }
        },
        methods:{
            toggleModal(value){
                this.$emit('open-modal', value);
            },
            AfficheMenuw(){
                console.log('qui est ton parent',this.$parent)
            },
            toggleMenu(value) {
                this.$emit("toggle-menu");
                this.$emit('change-page', value);
            },
            VerifToShow(){
                let module_enfant = this.$store.getters['getAllAcces'][1].module_enfant;
                if (!this.$store.getters['isAccesForDeveloppeur']) {
                    module_enfant.forEach((value)=>{
                        if (value.appellation =="liste_annee_univ" && value.etat ==1){
                            this.ListeMenu[0].isShow = true;
                            return false;
                        }
                        if (value.appellation =="lsite_type_formation" && value.etat ==1){
                            this.ListeMenu[1].isShow = true;
                            return false;
                        }
                        if (value.appellation =="liste_niveau" && value.etat ==1){
                            this.ListeMenu[2].isShow = true;
                            return false;
                        }
                        if (value.appellation =="liste_frais_formation" && value.etat ==1){
                            this.ListeMenu[3].isShow = true;
                            return false;
                        }
                    })
                }else{
                    this.ListeMenu.forEach(value => {
                        value.isShow = true;
                    })
                }

                console.log('module_enfant --->', module_enfant)
            },

        },
        mounted() {
            console.log('qui est ton parent',this.$parent);
            setTimeout(()=>{
                this.VerifToShow();
            },750)
        }

    }
</script>

<style scoped>

</style>

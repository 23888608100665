<template>
    <v-container fluid="">
        <v-row>
            <v-col cols="12">
                <v-card class="overflow-hidden">
                    <v-app-bar
                            color="green"
                            dark
                    >
                        <v-app-bar-nav-icon @click.native="toggleMenu"></v-app-bar-nav-icon>

                        <v-app-bar-title class="title-parametre">{{title}}</v-app-bar-title>

                        <v-spacer></v-spacer>
                        <p class="pt-4 pr-2"></p>
                        <v-btn color="grey lighten-5"
                               fab
                               small
                               dark
                               outlined
                               class="mr-1"
                               elevation="0"
                               @click.native="toggleModal"
                               title="Modifier Profil">
                            <v-icon>mdi-account</v-icon>
                        </v-btn>


                        <template v-slot:extension>
                            <v-tabs align-with-title>
                                <v-tab :to="d.link" v-for="(d,index) in data" :key="index"  v-show="d.isShow">
                                    {{d.menu}}
                                </v-tab>
                            </v-tabs>
                        </template>
                    </v-app-bar>
                </v-card>
            </v-col>
        </v-row>



    </v-container>
</template>

<script>

    export default {
        name: "BarNavigation",
        props:{
            title:{type:String},
            data:{type:Array},
        },

        methods:{
            toggleMenu() {
                this.$emit("menu-toggle",true);
            },
            toggleModal(){
                this.$emit("modal-toggle",true);
            },
        },
        mounted() {

        }
    }
</script>

<style scoped>

</style>

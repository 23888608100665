<template>
    <v-row justify="space-around">
        <v-col cols="auto">
            <v-dialog
                    v-model="dialog"
                    transition="dialog-bottom-transition"
                    max-width="820px"
                    persistent
            >
                <template v-slot:default="dialog">
                    <v-card>
                        <v-toolbar
                                class="elevation-0"
                                color="green"
                                dark
                        ><h2>{{titre}}</h2>
                        </v-toolbar>
                        <v-form
                                lazy-validation
                                ref="form"
                                id="formulaire"
                                v-on:submit.prevent="saveData"
                        >
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col
                                                cols="12" lg="6" md="6" sm="12"
                                        >
                                            <label for="">Date</label>
                                            <DatePicker  :is_default="true" class_name="margin-bottom-3"  name="date_inscription" v-model="crudFrais.date_paiement"></DatePicker>

                                            <label for="">Référence</label>
                                            <v-text-field
                                                    id="reference_paiement"
                                                    v-model="crudFrais.reference_paiement"
                                                    outlined
                                                    dense
                                                    readonly
                                                    class="uppercase-text margin-bottom-3"
                                                    hide-details
                                            ></v-text-field>
                                            <label for="">Frais Formation</label>
                                            <v-text-field
                                                    v-model="crudFrais.libeller_frais"
                                                    outlined
                                                    readonly
                                                    dense
                                                    class="uppercase-text margin-bottom-3"
                                                    hide-details
                                            ></v-text-field>

                                            <label for="">Montant à Payer</label>
                                            <vue-numeric
                                                    decimal-separator="."
                                                    currency="Ar"
                                                    v-bind:min="0"
                                                    separator="space"
                                                    v-bind:precision="0"
                                                    v-model="crudFrais.montant_frais"
                                                    readonly=""
                                                    currency-symbol-position="suffix"
                                                    class="margin-bottom-3 my-input-style float-right" >
                                            </vue-numeric>
                                        </v-col>

                                        <v-col
                                                cols="12" lg="6" md="6" sm="12"
                                        >
                                            <label for="">Caisse</label>
                                            <v-autocomplete
                                                    v-model="crudFrais.id_caisse"
                                                    :items="tab_tresorerie"
                                                    outlined
                                                    dense
                                                    required
                                                    hide-details
                                                    class="margin-bottom-3"
                                                    placeholder="choisissez  un caisse"
                                                    item-text="nom_caisse"
                                                    item-value="id"
                                                    :return-object="false"
                                                    clearable
                                            >

                                            </v-autocomplete>
                                            <div>
                                                <label for="">Montant</label>
                                                <vue-numeric
                                                        decimal-separator="."
                                                        currency="Ar"
                                                        v-bind:min="0"
                                                        separator="space"
                                                        v-bind:precision="0"
                                                        v-model="crudFrais.montant_payer"
                                                        currency-symbol-position="suffix"
                                                        @input="calculrestePayer"
                                                        class="margin-bottom-3 my-input-style float-right" >
                                                </vue-numeric>
                                            </div>

                                            <div>
                                                <label for="">Montant Reste à payer</label>
                                                <vue-numeric
                                                        decimal-separator="."
                                                        currency="Ar"
                                                        v-bind:min="0"
                                                        separator="space"
                                                        v-bind:precision="0"
                                                        v-model="reste_a_payer"
                                                        readonly=""
                                                        currency-symbol-position="suffix"
                                                        class="margin-bottom-3 my-input-style float-right" >
                                                </vue-numeric>
                                            </div>

                                            <label for="">Remarque</label>
                                            <v-text-field
                                                    id="commentaire"
                                                    v-model="crudFrais.commentaire"
                                                    outlined
                                                    dense
                                                    class="uppercase-text margin-bottom-3"
                                                    hide-details
                                            ></v-text-field>
                                        </v-col>


                                        <v-col cols="12" v-show="crudFrais.detail.length >0">
                                            <h5>HISTORIQUE REGLEMENT</h5>
                                            <v-simple-table dense class="my-3 matable">
                                                <template  v-slot:default>
                                                    <thead>
                                                    <tr>
                                                        <th class="text-left" width="100px">
                                                            Date Paiement
                                                        </th>
                                                        <th >
                                                            Remarque
                                                        </th>
                                                        <th width="120px">
                                                            Montant
                                                        </th>
                                                        <th width="80px" style="text-align: center">-</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(d,index) in crudFrais.detail" :key="index">
                                                            <td>
                                                                <span>{{d.date_paiement}}</span>
                                                            </td>
                                                            <td>
                                                                <span>{{d.commentaire}}</span>
                                                            </td>
                                                            <td>
                                                                <span>
                                                                    <vue-numeric
                                                                            decimal-separator="."
                                                                            currency="Ar"
                                                                            v-bind:min="0"
                                                                            separator="space"
                                                                            v-bind:precision="0"
                                                                            v-model="d.montant_payer"
                                                                            read-only
                                                                            currency-symbol-position="suffix"
                                                                            class="margin-bottom-3 my-input-style float-right" >
                                                                    </vue-numeric>
                                                                </span>
                                                            </td>
                                                            <td class="text-center">
                                                                <v-btn
                                                                       class=""
                                                                       red
                                                                       type="button"
                                                                       small
                                                                       color="error"
                                                                       @click="AnnulerPaiement(d.id)"
                                                                >
                                                                    <v-icon
                                                                            color="white"
                                                                            center
                                                                    >
                                                                        mdi-delete
                                                                    </v-icon>
                                                                </v-btn>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>



                            <v-card-actions class="justify-center " style="position: sticky !important; bottom: 0!important; background-color: white">
                                <v-container>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-btn
                                                    :disabled="crudFrais.montant_payer==0"
                                                    type="submit"
                                                    width="100%"
                                                    depressed
                                                    color="primary"
                                            >
                                                Enregistrer
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-btn
                                                    width="100%"
                                                    color="error"
                                                    depressed
                                                    @click="dialog.value = false"
                                            >Fermer
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>



                            </v-card-actions>
                        </v-form>

                    </v-card>
                </template>
            </v-dialog>
        </v-col>
        <v-snackbar
                v-model="snackBar.visible"

                :color="snackBar.color"
                :multi-line="snackBar.mode === 'multi-line'"
                :timeout="snackBar.timeout"
                :top="snackBar.position === 'top'">
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackBar.icon }}</v-icon>
                <v-layout column>
                    <div>
                        <strong>{{ snackBar.title }}</strong>
                    </div>
                    <div>{{ snackBar.text }}</div>
                </v-layout>
            </v-layout>
            <v-btn v-if="snackBar.timeout === 0" @click="snackBar.visible = false">
                <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>

        <ConfirmationAction ref="confirmation"/>
    </v-row>


</template>

<script>
    import axiosInstance from "@/AxiosInstance";
    // import axios from "axios";
    import DatePicker from "../DatePicker/DatePicker";
    import ConfirmationAction from "../ConfirmationAction/ConfirmationAction";
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    // const dateNow = year + '-' + month + '-' + day;
    const dateNow = year + '-' + month + '-' + day;
    console.log(dateNow);

    export default {
        components:{
            DatePicker,
            ConfirmationAction
        },
        name: "PayementFraisFormation",
        data: () => ({
            dialog: false,
            titre: 'Payement',
            reste_a_payer:0,
            crudFrais:{
                id:'',
                date_paiement:'',
                montant_payer:0,
                commentaire:'',
                reference_paiement:'',
                id_caisse:'',
                libeller_frais:'',
                code_frais:'',
                montant_frais:0,
                id_etudiant_niveau:'',
                detail:[],

            },
            snackBar: {
                color: "grey darken-4",
                icon:'mdi-success',
                position:'top',
                timeout:'',
                title:'',
                text:'',
                visible:false,

            },
            tab_tresorerie:[],
        }),
        computed:{

        },

        methods: {

            FetchDataTable(){
                this.$parent.showFrais();
                this.closeModal();
            },

            AnnulerPaiement(index){
                var lien = this.BASE_URL+"/etudiantfraisformation/annulerpaiement/"+index;
                this.$refs.confirmation.open("Confirmation","Êtes-vous sure d'annuler cette paiement?","100",lien)
            },

            /**
             * fonction permet de generer les dates et les reference
             * @returns {[string, string]}
             */
            getinfoDate(){
                const now = new Date();
                const year = now.getFullYear();
                const month = (now.getMonth() + 1).toString().padStart(2, '0');
                const day = now.getDate().toString().padStart(2, '0');
                const dateNow = year + '-' + month + '-' + day;

                const years = now.getFullYear().toString();
                const months = (now.getMonth() + 1).toString().padStart(2, '0'); // Mois commence à 0
                const days = now.getDate().toString().padStart(2, '0');
                const hours = now.getHours().toString().padStart(2, '0');
                const minutes = now.getMinutes().toString().padStart(2, '0');
                const seconds = now.getSeconds().toString().padStart(2, '0');

                const reference_date = years + months + days + hours + minutes + seconds;

                let data = [dateNow,reference_date];
                return data;
            },
            saveData() {

                const link = this.BASE_URL + '/etudiantfraisformation/paiement'
                const data = JSON.stringify(this.crudFrais);
                let formulaire = new FormData();
                formulaire.append('data', data)
                axiosInstance.post(link, formulaire, {
                    'Content-type': 'application/json'
                }).then((response) => {
                    console.log(response);
                    if (response.data.error==''){
                        this.$parent.showFrais();
                        this.closeModal();
                    }
                    this.crudFrais.date_paiement = '';
                    this.snackBar = {
                        text: response.data.error =='' ? response.data.message : response.data.error,
                        color: response.data.error =='' ? "success" : "warning" ,
                        visible: true,
                        timeout:'5000'
                    };
                }).catch(error => {
                    console.log('erreur', error);

                });
            },

            calculrestePayer(){
                this.reste_a_payer = Number.parseFloat(this.crudFrais.montant_frais ) - Number.parseFloat(this.crudFrais.montant_payer);
                this.crudFrais.montant_payer = (Number.parseFloat(this.reste_a_payer) <=0 ) ? this.crudFrais.montant_frais : this.crudFrais.montant_payer;
                this.reste_a_payer = (Number.parseFloat(this.reste_a_payer) <=0 ) ? 0 : this.reste_a_payer;
            },
            getTresorerie(){
                axiosInstance.get(this.BASE_URL+"/caisse/getall").then((resultat)=>{
                    this.tab_tresorerie = resultat.data;
                })
            },

            formatMontantFrais() {
                const montantNonFormate = this.crudFrais.montant_frais.replace(/\s/g, ''); // Supprimez les séparateurs de milliers

                const montantFormate = montantNonFormate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                this.crudFrais.montant_frais = montantFormate;
                this.$nextTick(() => {
                    this.crudFrais.montant_frais = montantFormate;
                })
            },

            openModalFilter(id,id_etudiant_niveau) {
                this.dialog = true;
                this.getDetailFraisformaton(id,id_etudiant_niveau);
            },
            closeModal(){
                this.dialog = false;
            },
            getDetailFraisformaton(id_payement,id_etudiant_niveau){
                axiosInstance.get(this.BASE_URL+'/etudiantfraisformation/get/'+id_payement).then((resultat)=>{
                    this.crudFrais = {
                        id:resultat.data[0].id,
                        date_paiement:dateNow,
                        montant_payer:resultat.data[0].montant_total,
                        commentaire:'',
                        reference_paiement:this.getinfoDate()[1],
                        id_caisse:'',
                        libeller_frais:resultat.data[0].libeller_frais,
                        code_frais:resultat.data[0].code_frais,
                        montant_frais:resultat.data[0].montant_total,
                        id_etudiant_niveau:id_etudiant_niveau,
                        detail: resultat.data[0].detail,
                    }
                    this.calculrestePayer();
                }).catch(error=>{
                    console.log(error)
                })
            }

        },
        created() {
            this.getTresorerie();
        },
    }
</script>

<style scoped>
    .my-input-style {
        outline: none;
        border: 1px solid #9e9e9e;
        width: 100%;
        padding: 8px;
        font-size: 16px;
        text-align: right!important;
    }
</style>

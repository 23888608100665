<template>
    <v-container fluid="">
        <v-row>
            <v-col cols="12">
                <BarNavigation :title="titre" :data="ListeMenu" @modal-toggle="toggleModal" @menu-toggle="toggleMenu"></BarNavigation>

                <router-view  >
                </router-view>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import BarNavigation from "@/components/BarNavigation/BarNavigation";

    export default {
        name: "MenuUtilisateur",
        components:{
            BarNavigation
        },
        data()  {
            return{
                titre:'INFO INSTITUT',
                ListeMenu:[
                    {
                        menu:'INFORMATION', link:'/info-institut/institut',isShow:false,
                    },
                    /*{
                        menu:'Liste Utilisateur', link:'/gestionutilisateur/liste-utilisateur',isShow:false,
                    },*/
                ]
            }
        },
        methods:{
            AfficheMenuw(){
                console.log('qui est ton parent',this.$parent)
            },
            toggleMenu(value) {
                this.$emit("toggle-menu");
                this.$emit('change-page', value);
            },
            toggleModal(value){
                this.$emit('open-modal', value);
            },
            VerifToShow(){
                let menu_etudiant = this.$store.getters['getAllAcces'][4].module_enfant;
                menu_etudiant.forEach((value)=>{
                    if (value.appellation =="modifier_institut" && value.etat ==1){
                        this.ListeMenu[0].isShow = true;
                        return false;
                    }
                })
                console.log('module_enfant ', menu_etudiant)
            },
        },
        mounted() {
            console.log('qui est ton parent',this.$parent);
            setTimeout(()=>{
                this.VerifToShow();
            },750)
        }

    }
</script>

<style scoped>

</style>

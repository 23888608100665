import { render, staticRenderFns } from "./ReleveNote.vue?vue&type=template&id=0a4989dd&scoped=true"
import script from "./ReleveNote.js?vue&type=script&lang=js&external"
export * from "./ReleveNote.js?vue&type=script&lang=js&external"
import style0 from "./ReleveNote.vue?vue&type=style&index=0&id=0a4989dd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a4989dd",
  null
  
)

export default component.exports
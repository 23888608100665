import axios from 'axios'

const TOKEN = localStorage.getItem("token");
// Créez une instance personnalisée d'Axios avec des en-têtes d'autorisation
const axiosInstance = axios.create({
    headers: {
        'Authorization': `Bearer ${TOKEN}`,
    }
})

export default axiosInstance

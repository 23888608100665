<template>
    <v-app>
        <v-main>

            <transition name="modal-fade">
                <div class="menu-modal" v-show="showMenu">
                    <div class="menu-content">
                        <v-btn @click="showMenu = false" color="red">
                            <v-icon large color="black">
                                mdi-close
                            </v-icon>
                        </v-btn>
                        <v-row justify="center" align="center">

                            <v-col cols="12" sm="6" md="3" lg="3" v-for="(d,index) in All_Data" :key="index" v-show="(d.isShow ||isDeveloppeur)">
                                <!-- Utilisation de <router-link> au lieu de <v-card> -->
                                <router-link :to="d.route" class="my-3 mx-2 card-link" tile @click.native="hideMenu" style="text-decoration: none!important;">
                                    <v-card class="card-shadow" style="cursor: pointer;">
                                        <v-img :src="BASE_URL+''+d.link_img" width="150px"
                                               class="mx-auto"></v-img>
                                        <h4 class="text-center title-menu">{{d.nom_module}}</h4>
                                    </v-card>
                                </router-link>
                            </v-col>

                            <v-col cols="12" md="6" lg="4">
                                <!-- Utilisation de <router-link> au lieu de <v-card> -->
                                <router-link to="/" class="my-3 mx-2 card-link" style="text-decoration: none !important;" tile @click.native="deconnecter">
                                    <v-card class="card-shadow" style="cursor: pointer;">
                                        <v-img :src="BASE_URL+'/public/assets/img/se-deconnecter.png'" width="150px"
                                               class="mx-auto"></v-img>
                                        <h4 class="text-center title-menu">Se déconnecter </h4>
                                    </v-card>
                                </router-link>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </transition>

            <!-- Transition pour le contenu principal (router-view) -->
            <transition-group name="main-fade" mode="out-in">
                <v-row v-show="!showMenu" key="router-view">
                    <router-view :show-menu="teste" @open-modal="onOpenModal" @change-page="onChangePage"></router-view>
                </v-row>
            </transition-group>
        </v-main>

        <v-snackbar
                v-model="snackBar.visible"
                :color="snackBar.color"
                :multi-line="snackBar.mode === 'multi-line'"
                :timeout="snackBar.timeout"
                :top="snackBar.position === 'top'">
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackBar.icon }}</v-icon>
                <v-layout column>
                    <div>
                        <strong>{{ snackBar.title }}</strong>
                    </div>
                    <div>{{ snackBar.text }}</div>
                </v-layout>
            </v-layout>
            <v-btn v-if="snackBar.timeout === 0" @click="snackBar.visible = false">
                <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>

        <v-dialog
                transition="dialog-bottom-transition"
                max-width="500"
                v-model="dialogModal"
                scrollable
                :hide-overlay="false"
                persistent
        >
            <v-card>
                <v-toolbar
                        color="#4caf50"
                        dark
                        class="pl-3"
                >
                    MON PROFIL
                </v-toolbar>
                <v-form
                        lazy-validation
                        ref="form"
                        id="formulaire"
                        v-on:submit.prevent="saveData"
                >

                    <v-card-text>
                        <v-container fluid style="padding: 0!important;">
                            <v-row>
                                <input type="hidden" name="id" v-model="crudForm.id">
                                <v-col
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                >
                                    <v-text-field
                                            v-model="crudForm.nom_utilisateur"
                                            label="Nom"
                                            outlined
                                            dense
                                            name="nom_utilisateur"
                                            class="text-capitalize margin-bottom-3"
                                            hide-details
                                            clearable
                                    ></v-text-field>


                                    <v-text-field
                                            v-model="crudForm.login"
                                            label="Login"
                                            outlined
                                            dense
                                            name="login"
                                            class="text-capitalize margin-bottom-3"
                                            hide-details
                                            clearable
                                    ></v-text-field>


                                    <v-text-field
                                            v-model="crudForm.mdp"
                                            label="Mots de passe"
                                            outlined
                                            dense
                                            name="mdp"
                                            type="password"
                                            class="text-capitalize margin-bottom-3"
                                            hide-details
                                            clearable
                                    ></v-text-field>

                                    <v-text-field
                                            v-model="crudForm.mdp1"
                                            label="Confirmation Mots de passe"
                                            outlined
                                            dense
                                            name="mdp1"
                                            type="password"
                                            class="text-capitalize margin-bottom-3"
                                            hide-details
                                            clearable
                                    ></v-text-field>

                                </v-col>
                            </v-row>
                        </v-container>

                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="justify-center">
                        <v-container>
                            <v-row>
                                <v-col cols="6">
                                    <v-btn :disabled="disabled_btn" type="submit" width="100%" color="primary">
                                        Enregistrer
                                    </v-btn>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn width="100%" color="blue-grey darken-2" elevation="0" dark block
                                           @click.stop="closeModalProfil">Fermer
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>

                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
    import axiosInstance from "@/AxiosInstance";


    export default {
        name: 'App',
        props: {
        },
        components: {},

        data() {
            return {

                teste: 'teste',
                showMenu: true,
                base_url: this.BASE_URL,
                BASE_URL: this.$root.BASE_URL,
                Modules: [],
                Submenus: [],
                Permissions: [],
                All_Data: this.$store.getters['getAllAcces'],

                dialogModal: false,
                disabled_btn: false,
                crudForm: this.$store.getters['getUsers'],
                isDeveloppeur:this.$store.getters['isAccesForDeveloppeur'],
                snackBar: {
                    color: "grey darken-4",
                    icon: 'mdi-success',
                    position: 'top',
                    timeout: '',
                    title: '',
                    text: '',
                    visible: false,

                },
            }
        },
        methods: {
            hideMenu() {
                this.showMenu = false;
            },
            onOpenModal(value) {
                this.dialogModal = value;
                this.crudForm.mdp = '';
                this.crudForm.mdp1 = '';
            },
            closeModalProfil() {
                this.dialogModal = false;
            },
            onChangePage: function (value) {
                this.showMenu = value
            },
            deconnecter: function () {
                localStorage.removeItem("token");
                location.reload();
            },

            saveData() {

                this.disabled_btn = true;

                const link = this.BASE_URL + '/utilisateur/updates';
                const data = JSON.stringify(this.crudForm);
                let formulaire = new FormData();
                formulaire.append('data', data)
                axiosInstance.post(link, formulaire, {
                    'Content-type': 'application/json'
                }).then((response) => {
                    this.disabled_btn = false;
                    console.log(response);
                    if (response.data.error == '') {
                        this.closeModalProfil();
                    }

                    this.snackBar = {
                        text: response.data.error == '' ? response.data.message : response.data.error,
                        color: response.data.error == '' ? "success" : "warning",
                        visible: true,
                        timeout: '5000'
                    };
                }).catch(error => {
                    console.log('erreur', error);

                });
            },
        },
        mounted() {
            console.log('All_Data',this.$store)

        },
        created() {
            document.body.style.zoom = 0.80;
        }
    };
</script>
<style scoped>
    .card-shadow {
        box-shadow: 0 4px 8px -2px rgba(0, 128, 0, 0.2), -8px 0 8px -2px rgba(0, 128, 0, 0.2) !important;
    }

    nav a.router-link-exact-active {
        color: #42b983;
    }

    .card-shadow {
        box-shadow: 0 4px 8px -2px rgba(0, 128, 0, 0.2), -8px 0 8px -2px rgba(0, 128, 0, 0.2) !important;
        transition: box-shadow 0.3s; /* Ajoute une transition en douceur */
    }

    .card-shadow:hover {
        box-shadow: 0 4px 8px -2px rgba(0, 128, 0, 0.5), -8px 0 8px -2px rgba(0, 128, 0, 0.5) !important;
    }

    .menu-modal {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 2;
        align-items: center;
        justify-content: center;
    }

    .menu-content {
        background: white;
        padding: 20px;
        border-radius: 0;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
        max-width: none;
        max-height: none;
        overflow: auto !important;
    }

    .main-content {
        z-index: 1;
    }

    .modal-fade-enter-active, .modal-fade-leave-active {
        transition: opacity 0.5s;
    }

    .modal-fade-enter, .modal-fade-leave-to {
        opacity: 0;
    }
</style>

import axiosInstance from "@/AxiosInstance";
import ConfirmationAction from "../../../components/ConfirmationAction/ConfirmationAction.vue";
import CompoentFiltre from "../../../components/CompoentFiltre/CompoentFiltre";
import DatePicker from "../../../components/DatePicker/DatePicker.vue";


const now = new Date();
const year = now.getFullYear();
const month = (now.getMonth() + 1).toString().padStart(2, '0');
const day = now.getDate().toString().padStart(2, '0');
const dateNow = year + '-' + month + '-' + day;

console.log(dateNow);
export default {
    name: 'FraisformationHistorique',
    components: {
        ConfirmationAction,
        CompoentFiltre,
        DatePicker
    },

    data() {
        return {
            total_solde: 0,
            BASE_URL: this.$root.BASE_URL,
            isDeveloppeur:this.$store.getters['isAccesForDeveloppeur'],
            dialogModal: false,
            tab_caisse:[],
            ListedesFormation: [],
            img_default: this.BASE_URL + '/public/assets/img/icon_studen.png',
            snackBar: {
                color: "grey darken-4",
                icon: 'mdi-success',
                position: 'top',
                timeout: '',
                title: '',
                text: '',
                visible: false,

            },
            headers: [
                {
                    text: 'A-U',
                    value: 'annee_universitaire',
                    class: "text-uppercase text-left",
                },
                {
                    text: 'Date',
                    value: 'date_flux_formatted',
                    class: "text-left",
                },
                {
                    text: 'N° Matricule',
                    value: 'numero_matricule',
                    class: "text-left",
                },
                {
                    text: 'Référence',
                    value: 'reference_flux',
                    class: "text-left",
                },
                {
                    text: 'Intitulé',
                    value: 'motif_flux',
                    class: "text-left",
                },
                {
                    text: 'solde initial',
                    value: 'solde_avant',
                    class: "text-right",
                    cellClass: 'text-right',
                },

                {
                    text: 'Crédit',
                    value: 'montant_credit',
                    class: "text-right",
                    cellClass: 'text-right'
                },

                {
                    text: 'Débit',
                    value: 'montant_debit',
                    class: "text-left",
                },
                {
                    text: 'solde final',
                    value: 'solde_apres',
                    class: "text-right",
                    cellClass: 'text-right'
                },
                {
                    text: 'Nom',
                    value: 'nom_etudiant',
                    class: "text-left",
                },
                {
                    text: 'Prénom(s)',
                    value: 'prenom_etudiant',
                    class: "text-left",
                },


                {
                    text: 'Type Formation',
                    value: 'libeller_type_formation',
                    class: "text-left",
                },
                {
                    text: 'Parcours',
                    value: 'nom_filieres',
                    class: "text-left",
                },
                {
                    text: 'Niveau',
                    value: 'libeller_niveau',
                    class: "text-left",
                },
                {
                    text: 'Utlisateur',
                    value: 'nom_utilisateur',
                    class: "text-left",
                },


            ],
            recap:[
                {total_debit:0,total_credit:0,total_solde:0}
            ],
            DataTable: {
                fullData: [],
                order_by: "id",
                order: "desc",
                per_page: 10,
                last_page: 0,
                page: 1,
                total: 0,
                loading: true,
                recherche_critere: {
                    filtre: '',
                    libeller_type_formation: '',
                    nom_filieres: '',
                    libeller_niveau: '',
                    date_debut: '',
                    date_fin: '',
                }
            },
            Filtre: [],
            tab_typeFormation: [],
            tab_filiere: [],
            tab_niveau: [],
            crudForm: {
                id: '',
                id_caisse: '',
                id_utilisateur: '',
                reference_flux: '',
                date_flux: '',
                motif_flux: '',
                type_flux: '',
                montant: 0,
                etat_sup_niveau: 0,
            },
            list_flux:[{libeller:'ENTREE',value:'CREDIT'},{libeller: 'SORTIE',value: 'DEBIT'}],
            acces:{
                lecture:false,
                create:false,
                edit:false,
                delete:false,
            },
            ELEMENT: this.$store.getters['getAllAcces'][2].module_enfant,
        }
    },
    methods: {

        exportToExcel(){
            const params = new URLSearchParams();
            params.append('filtre', this.DataTable.recherche_critere.filtre);
            params.append('libeller_niveau', this.DataTable.recherche_critere.libeller_niveau);
            params.append('nom_filieres', this.DataTable.recherche_critere.nom_filieres);
            params.append('date_debut', this.DataTable.recherche_critere.date_debut);
            params.append('date_fin', this.DataTable.recherche_critere.date_fin);
            params.append('libeller_type_formation', this.DataTable.recherche_critere.libeller_type_formation);
            window.open(`${this.BASE_URL}/caisseflux/exportexcel?${params.toString()}`,'_blank');
        },
        getAllCaisse(){
            axiosInstance.get(this.BASE_URL+'/caisse/getall/').then((result)=>{
                this.tab_caisse = result.data;
            })
        },

        getinfoDate(){
            const now = new Date();
            const year = now.getFullYear();
            const month = (now.getMonth() + 1).toString().padStart(2, '0');
            const day = now.getDate().toString().padStart(2, '0');
            const dateNow = year + '-' + month + '-' + day;

            const years = now.getFullYear().toString();
            const months = (now.getMonth() + 1).toString().padStart(2, '0'); // Mois commence à 0
            const days = now.getDate().toString().padStart(2, '0');
            const hours = now.getHours().toString().padStart(2, '0');
            const minutes = now.getMinutes().toString().padStart(2, '0');
            const seconds = now.getSeconds().toString().padStart(2, '0');

            const reference_date = years + months + days + hours + minutes + seconds;

            let data = [dateNow,reference_date];
            return data;
        },
        InsertElement() {
            this.crudForm = {
                id: '',
                id_caisse: '',
                id_utilisateur: '',
                reference_flux: this.getinfoDate()[1],
                date_flux: '',
                motif_flux: '',
                type_flux: '',
                montant: '',
                etat_sup_niveau: 0,
            };
            this.openModal();
        },
        openModal() {
            this.dialogModal = true;
        },
        closeModal() {
            this.dialogModal = false;
        },
        openModalFiltre() {
            this.$refs.filter.openModalFilter();
        },
        getListypeFormation() {
            axiosInstance.get(this.BASE_URL + "/typeformation/getall").then((resultat) => {
                this.tab_typeFormation = resultat.data;
                this.InitElementFiltre(this.tab_typeFormation, 'type_formation');
            })
        },
        getListeFiliere() {
            axiosInstance.get(this.BASE_URL + "/filiere/getall/").then((resultat) => {
                this.tab_filiere = resultat.data;
                this.InitElementFiltre(this.tab_filiere, 'filiere');
                this.getListeNiveau();
            })
        },

        getListeNiveau() {
            axiosInstance.get(this.BASE_URL + "/niveau/getallniveaudistinct/").then((resultat) => {
                this.tab_niveau = resultat.data;
                this.InitElementFiltre(this.tab_niveau, 'niveau');
            })
        },

        InitElementFiltre(data, type) {

            if (type == 'type_formation') {
                this.Filtre.push({
                    title: 'Type de Formation',
                    dataSearch: data,
                    item_text: 'libeller_type_formation',
                    item_value: 'id',
                    modelValue: ''
                })
            }
            if (type == 'filiere') {
                this.Filtre.push({
                    title: 'Parcours',
                    dataSearch: data,
                    item_text: 'nom_filieres',
                    item_value: 'id',
                    modelValue: ''
                })
            }
            if (type == 'niveau') {
                this.Filtre.push({
                    title: 'Niveau',
                    dataSearch: data,
                    item_text: 'libeller_niveau',
                    item_value: 'libeller_niveau',
                    modelValue: ''
                })
            }
        },
        saveData() {
            const link = this.BASE_URL + '/caisse/enregistrementflux'
            const data = JSON.stringify(this.crudForm);
            let formulaire = new FormData();
            formulaire.append('data', data)
            axiosInstance.post(link, formulaire, {
                'Content-type': 'application/json'
            }).then((response) => {
                console.log(response);
                if (response.data.error==''){
                    this.closeModal();
                    this.FetchDataTable();
                }
                this.snackBar = {
                    text: response.data.error =='' ? response.data.message : response.data.error,
                    color: response.data.error =='' ? "success" : "warning" ,
                    visible: true,
                    timeout:'5000'
                };
            }).catch(error => {
                console.log('erreur', error);
            });
        },


        FetchDataTable() {
            let formulaire = new FormData();
            formulaire.append('filtre', this.DataTable.recherche_critere.filtre);
            formulaire.append('libeller_niveau', this.DataTable.recherche_critere.libeller_niveau);
            formulaire.append('nom_filieres', this.DataTable.recherche_critere.nom_filieres);
            formulaire.append('date_debut', this.DataTable.recherche_critere.date_debut);
            formulaire.append('date_fin', this.DataTable.recherche_critere.date_fin);
            formulaire.append('libeller_type_formation', this.DataTable.recherche_critere.libeller_type_formation);
            formulaire.append("order", this.DataTable.order);
            formulaire.append("order_by", this.DataTable.order_by);
            formulaire.append("per_page", this.DataTable.per_page);
            formulaire.append("page", this.DataTable.page);

            this.DataTable.loading = true;
            axiosInstance.post(this.BASE_URL + "/caisse/fetchdataflux", formulaire).then((response) => {
                this.DataTable.per_page = parseInt(response.data.per_page);
                this.DataTable.fullData = response.data.data;
                this.recap = response.data.recap;
                this.DataTable.page = parseInt(response.data.page);
                this.DataTable.loading = false;
                this.DataTable.last_page = parseInt(response.data.last_page);

            });
        },


        DeleteRow(data) {
            var lien = this.BASE_URL + "/etudiantfraisformation/annulerpaiement/" + data.id;
            this.$refs.confirmation.open("Confirmation", "Êtes-vous sure d'annuler cette paiement?", "100", lien)
        },


    },
    computed: {},
    watch: {},
    mounted: function () {
        this.FetchDataTable();
        this.getListypeFormation();
        this.getListeFiliere();
        this.getAllCaisse();

        this.ELEMENT.forEach(value =>{
            if (value.appellation==="liste_flux" && value.etat == 1){
                this.acces.lecture = true;
            }

            if (value.appellation==="ajout_flux" && value.etat == 1){
                this.acces.create = true;
            }

        })
    }
};

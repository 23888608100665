
import Vue from 'vue'
import Vuex from 'vuex'
import developpeurModule from './developpeur'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        developpeur: developpeurModule
    }
})

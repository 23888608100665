import axios from "axios";
import ConfirmationAction from "../../../components/ConfirmationAction/ConfirmationAction.vue";


export default {
    name: 'CaisseListe',
    components: {
        ConfirmationAction
    },

    data() {
        return {
            BASE_URL: this.$root.BASE_URL,
            isDeveloppeur:this.$store.getters['isAccesForDeveloppeur'],
            dialogModal: false,
            crudForm: {
                id: '',
                nom_caisse: '',
                solde_caisse: 0,
                etat_sup_caisse: 0
            },
            snackBar: {
                color: "grey darken-4",
                icon: 'mdi-success',
                position: 'top',
                timeout: '',
                title: '',
                text: '',
                visible: false,

            },
            headers: [


                {
                    text: 'Nom Caisse',
                    value: 'nom_caisse',
                    class: "text-uppercase text-left",
                },
                {
                    text: 'Montant Total',
                    value: 'solde_caisse',
                    class: "text-uppercase text-right",
                    width: '300px',
                    cellClass: 'text-right',
                    style:'text-alight:right'

                },
                {
                    text: '-', value: 'actions', sortable: false, width: "110px",
                    align: 'center',
                    class: "text-center"
                },
            ],
            total_solde: 0,
            DataTable: {
                fullData: [],
                order_by: "id",
                order: "asc",
                per_page: 10,
                last_page: 0,
                page: 1,
                total: 0,
                loading: true,
                recherche_critere: {
                    filtre: '',
                }
            },
            acces:{
                lecture:false,
                create:false,
                edit:false,
                delete:false,
            },
            ELEMENT: this.$store.getters['getAllAcces'][2].module_enfant,
        }
    },
    methods: {
        FetchDataTable() {
            let formulaire = new FormData();
            formulaire.append('filtre', this.DataTable.recherche_critere.filtre);
            formulaire.append("order", this.DataTable.order);
            formulaire.append("per_page", this.DataTable.per_page);
            formulaire.append("page", this.DataTable.page);

            this.DataTable.loading = true;
            axios.post(this.BASE_URL + "/caisse/fetchdata", formulaire).then((response) => {
                this.DataTable.per_page = parseInt(response.data.per_page);
                this.DataTable.fullData = response.data.data;
                this.DataTable.page = parseInt(response.data.page);
                this.DataTable.loading = false;
                this.DataTable.last_page = parseInt(response.data.last_page);
                this.total_solde = parseFloat(response.data.solde)
            });
        },

        editRow(data) {
            axios.post(this.BASE_URL + "/caisse/get/" + data.id).then((response) => {
                this.crudForm = response.data[0];
                this.openModal();
            }).catch(error => {
                console.log(error)
            });
        },
        DeleteRow(data) {
            var lien = this.BASE_URL + "/caisse/delete/" + data.id;
            console.log(lien)
            this.$refs.confirmation.open("Confirmation", "Voulez vous vraiment supprimer cette ligne?", "100", lien)
        },
        InsertElement() {

            this.crudForm = {
                id: '',
                nom_caisse: '',
                solde_caisse: 0,
                etat_sup_caisse: 0,
            };
            this.openModal();
        },


        saveData() {

            const link = this.crudForm.id == 0 ? this.BASE_URL + '/caisse/create' :
                this.BASE_URL + '/caisse/update';
            const data = JSON.stringify(this.crudForm);
            let formulaire = new FormData();
            formulaire.append('data', data)
            axios.post(link, formulaire, {
                'Content-type': 'application/json'
            }).then((response) => {
                console.log(response);
                if (response.data.error == '') {
                    this.closeModal();
                    this.FetchDataTable();
                }

                this.snackBar = {
                    text: response.data.error == '' ? response.data.message : response.data.error,
                    color: response.data.error == '' ? "success" : "warning",
                    visible: true,
                    timeout: '5000'
                };
            }).catch(error => {
                console.log('erreur', error);

            });
        },

        openModal() {
            this.dialogModal = true;
        },
        closeModal() {
            this.dialogModal = false;
        },


    },
    computed: {},
    watch: {},
    mounted: function () {
        this.FetchDataTable();
        this.ELEMENT.forEach(value =>{
            if (value.appellation==="liste_caisse" && value.etat == 1){
                this.acces.lecture = true;
            }

            if (value.appellation==="ajout_caisse" && value.etat == 1){
                this.acces.create = true;
            }
            if (value.appellation==="modifi_caisse" && value.etat == 1){
                this.acces.edit = true;
            }

            if (value.appellation==="supprimer_caisse" && value.etat == 1){
                this.acces.delete = true;
            }
        })
    }
};

import axios from "axios";
import ConfirmationAction from "../../../components/ConfirmationAction/ConfirmationAction.vue";

export default {
    name: 'utilisateur',
    components: {
        ConfirmationAction
    },

    data() {
        return {
            BASE_URL: this.$root.BASE_URL,
            tab_groupe_utilisasteur:[{id:'',nom_groupe:''}],
            crudForm: {
                id: '',
                nom_groupe: '',
                etat_suppression:'',
                groupe_acces:[],
            },
            snackBar: {
                color: "grey darken-4",
                icon:'mdi-success',
                position:'top',
                timeout:'',
                title:'',
                text:'',
                visible:false,
            },
            acces:{
                lecture:false,
                create:false,
                edit:false,
                delete:false,
            },
            isDeveloppeur:this.$store.getters['isAccesForDeveloppeur'],
            ELEMENT: this.$store.getters['getAllAcces'][3].module_enfant,
            disabled_btn:false,
        }
    },
    methods: {
        toggleAllDetails(group) {
            group.module_enfant.forEach(detail => {
                detail.etat = group.isSelected ? '1' : '0';
            });
        },
        checkAllDetails(group) {
            const allChecked = group.module_enfant.every(detail => detail.etat === '1');
            group.isSelected = allChecked;
        },

        selectGroupe(){
            axios.get(this.BASE_URL+'/groupeutilisateur/getallmoduleenfantparnomgroupe/'+this.crudForm.nom_groupe).then((resultat)=>{
                this.crudForm.groupe_acces = resultat.data;
            })
        },
        getGroupeUtilisateur(){
            axios.get(this.BASE_URL+'/groupeutilisateur/getall').then((resultat)=>{
                this.tab_groupe_utilisasteur = resultat.data;
            })
        },
        AccesElement(id){
            axios.get(this.BASE_URL+'/groupeutilisateur/getelement/'+id).then((resultat)=>{
                this.crudForm = resultat.data;
            })
        },
        saveData() {
            this.disabled_btn = true;
            const link = this.BASE_URL + '/groupeutilisateur/saveacces';
            const data = JSON.stringify(this.crudForm);
            let formulaire = new FormData();
            formulaire.append('data', data)
            axios.post(link, formulaire, {
                'Content-type': 'application/json'
            }).then((response) => {
                this.disabled_btn = false;
                console.log(response);
                if (response.data.error==''){

                    this.AccesElement(response.data.id_groupe_utilisateur);
                    this.getGroupeUtilisateur();
                }

                this.snackBar = {
                    text: response.data.error =='' ? response.data.message : response.data.error,
                    color: response.data.error =='' ? "success" : "warning" ,
                    visible: true,
                    timeout:'5000'
                };
            }).catch(error => {
                console.log('erreur', error);

            });
        },

        getAllAcces(){
            axios.get(this.BASE_URL+'/groupeutilisateur/getallmoduleenfant').then((resultat)=>{
                this.crudForm.groupe_acces = resultat.data;
            })
        },



    },
    computed: {},
    watch: {},
    mounted: function () {
        this.getGroupeUtilisateur();
        this.getAllAcces();

        this.ELEMENT.forEach(value =>{
            if (value.appellation==="liste_utilisateur" && value.etat == 1){
                this.acces.lecture = true;
                this.acces.create = true;
            }


        })
    }
};

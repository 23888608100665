
import axiosInstance from "@/AxiosInstance";
import ConfirmationAction from "../../../components/ConfirmationAction/ConfirmationAction.vue";
import CompoentFiltre from "../../../components/CompoentFiltre/CompoentFiltre";
import EtatFraisFormation from "@/components/EtatFraisFormation/EtatFraisFormation.vue";
import ShowPdf from "@/components/ShowPdf/ShowPdf.vue";

export default {
    name: 'filiere',
    components: {
        ShowPdf,
        ConfirmationAction,
        CompoentFiltre,
        EtatFraisFormation
    },

    data() {
        return {
            BASE_URL: this.$root.BASE_URL,
            dialogModal: false,
            ListedesFormation:[],
            img_default:this.BASE_URL+'/public/assets/img/avatar-diplome.png',
            snackBar: {
                color: "grey darken-4",
                icon:'mdi-success',
                position:'top',
                timeout:'',
                title:'',
                text:'',
                visible:false,

            },
            headers: [
                {
                    text: '',
                    value: 'photo',
                    class: "text-uppercase text-left",
                },
                {
                    text: 'A-U',
                    value: 'annee_universitaire',
                    class: "text-uppercase text-left",
                },
                {
                    text: 'Date Inscription',
                    value: 'date_inscription_en_formatted',
                    class: "text-uppercase text-left",
                },
                {
                    text: 'N° Matricule',
                    value: 'numero_matricule',
                    class: "text-left",
                },
                {
                    text: 'Nom',
                    value: 'nom_etudiant',
                    class: "text-left",
                },
                {
                    text: 'Prénom(s)',
                    value: 'prenom_etudiant',
                    class: "text-left",
                },
                {
                    text: 'Genre',
                    value: 'sexe_etudiant',
                    class: "text-left",
                },
                {
                    text: 'Type Formation',
                    value: 'libeller_type_formation',
                    class: "text-left",
                },
                {
                    text: 'Parcours',
                    value: 'nom_filieres',
                    class: "text-left",
                },
                {
                    text: 'Niveau',
                    value: 'libeller_niveau',
                    class: "text-left",
                },
                {
                    text: 'Adresse',
                    value: 'adresse_etudiant',
                    class: "text-left",
                },
                {
                    text: 'Contacte',
                    value: 'telephone_etudiant',
                    class: "text-left",
                },
                {
                    text: 'Mail',
                    value: 'mail_etudiant',
                    class: "text-left",
                },
                {   text: '-',
                    value: 'actions', sortable: false,width:"110px",
                    align:'center',
                    class: "text-center other-text fixed_column_right",
                    cellClass: "text-center other-text fixed_column_right",
                },
            ],
            isDeveloppeur:this.$store.getters['isAccesForDeveloppeur'],
            DataTable: {
                fullData: [],
                order_by: "id",
                order: "asc",
                per_page: 10,
                last_page:0,
                page: 1,
                total: 0,
                loading: true,
                recherche_critere: {
                    filtre: '',
                    libeller_type_formation: '',
                    nom_filieres: '',
                    libeller_niveau: '',
                }
            },
            Filtre:[],
            tab_typeFormation:[],
            tab_filiere:[],
            tab_niveau:[],
            acces:{
                lecture:false,
                create:false,
                edit:false,
                delete:false,
            },
            fileLoaded: false,
            preview_link:'',
            link_pdf:'',
            title_pdf:'',
        }
    },
    methods: {
        viewEtatFrais(id_etudiant_niveau){
            this.$refs.etat_frais_formation.getEtatFraisFormation(id_etudiant_niveau);
        },
        loadFile() {
            // Déclencher le clic sur le champ de fichier
            this.$refs.fileInput.click();
        },

        createImages(file) {
            var reader = new FileReader();
            var that = this;
            reader.onload = (e) => {
                that.preview_link = e.target.result;
            };
            reader.readAsDataURL(file);
        },

        uploadImage(event){
            const URL = this.BASE_URL + '/etudiant/enregisterstudendbyexcel';

            let data = new FormData();
            data.append('name', 'file-input');
            data.append('file-input', event.target.files[0]);

            console.log('data',data)

            console.log(event.target.files[0]);
            var files = event.target.files || event.dataTransfer.files;
            this.createImages(files[0]);
            let config = {
                header: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    console.log(percentCompleted)
                }
            }
            axiosInstance.post(
                URL,
                data,
                config
            ).then(
                response => {
                    console.log('image upload response > ', response);
                    this.$parent.showLoading = false;
                    if (response.data.cle==="ok"){

                        this.FetchDataTable();

                    }else{
                        console.log('il y a erreur')
                    }
                }
            ).catch(error => {
                console.log(error);
            })
        },

        donwloadModele(){
            window.open(this.BASE_URL + "/assets/modele/modele_etudiant.xlsx", '_blank');
        },
        GenerateCertificat(id_niveu_etudiant){
            window.open(this.BASE_URL + "/etudiant/certificat/" + id_niveu_etudiant, '_blank');
        },
        printrelevernote(data){
            console.log(data);
            let formulaire = new FormData();
            formulaire.append('id_niveau',data.id_niveau);
            formulaire.append('id_filiere',data.id_filiere);
            formulaire.append('id_etudiant',data.id_etudiant);
            axiosInstance.post(this.BASE_URL+'/note/printrelevernote',formulaire).then((response)=> {
                this.link_pdf = response.data.pdfUrl;
                this.$refs.print_etudiant.openModalPrint();
                this.title_pdf = "Liste relevé de note";
            })
        },
        getListypeFormation(){
            axiosInstance.get(this.BASE_URL+"/typeformation/getall").then((resultat)=>{
                this.tab_typeFormation = resultat.data;
                this.InitElementFiltre(this.tab_typeFormation,'type_formation');
            })
        },
        getListeFiliere(){
            axiosInstance.get(this.BASE_URL+"/filiere/getall/").then((resultat)=>{
                this.tab_filiere = resultat.data;
                this.InitElementFiltre(this.tab_filiere,'filiere');
                this.getListeNiveau();
            })
        },

        getListeNiveau(){
            axiosInstance.get(this.BASE_URL+"/niveau/getallniveaudistinct/").then((resultat)=>{
                this.tab_niveau = resultat.data;
                this.InitElementFiltre(this.tab_niveau,'niveau');
            })
        },

        InitElementFiltre(data,type){

            if (type =='type_formation'){
                this.Filtre.push({
                    title: 'Type de Formation',
                    dataSearch: data,
                    item_text: 'libeller_type_formation',
                    item_value:'id',
                    modelValue:''
                })
            }
            if (type=='filiere'){
                this.Filtre.push({
                    title: 'Parcours',
                    dataSearch: data,
                    item_text: 'nom_filieres',
                    item_value:'id',
                    modelValue:''
                })
            }
            if (type=='niveau'){
                this.Filtre.push({
                    title: 'Niveau',
                    dataSearch: data,
                    item_text: 'libeller_niveau',
                    item_value:'libeller_niveau',
                    modelValue:''
                })
            }
        },

        openModalFiltre(){
            this.$refs.filter.openModalFilter();
        },

        FetchDataTable(){
            let formulaire = new FormData();
            formulaire.append('filtre', this.DataTable.recherche_critere.filtre);
            formulaire.append('libeller_niveau', this.DataTable.recherche_critere.libeller_niveau);
            formulaire.append('nom_filieres', this.DataTable.recherche_critere.nom_filieres);
            formulaire.append('libeller_type_formation', this.DataTable.recherche_critere.libeller_type_formation);
            formulaire.append("order", this.DataTable.order);
            formulaire.append("per_page", this.DataTable.per_page);
            formulaire.append("page", this.DataTable.page);

            this.DataTable.loading = true;
            axiosInstance.post(this.BASE_URL + "/etudiant/fetchdata", formulaire).then((response) => {
                this.DataTable.per_page = parseInt(response.data.per_page);
                this.DataTable.fullData = response.data.data;
                this.DataTable.page = parseInt(response.data.page);
                this.DataTable.loading = false;
                this.DataTable.last_page = parseInt(response.data.last_page);

            });
        },

        editRow(data){
            axiosInstance.post(this.BASE_URL + "/etudiant/get/"+data.id).then((response) => {
                this.crudForm = response.data[0];
                this.openModal();
            }).catch(error =>{
                console.log(error)
            });
        },

        DeleteRow(data){
            var lien = this.BASE_URL+"/etudiant/delete/"+data.id_etudiant_niveau;
            console.log(lien)
            this.$refs.confirmation.open("Confirmation","Voulez vous vraiment supprimer cette ligne?","100",lien)
        },
        getModule(){
            this.$store.getters['getAllAcces'][0].module_enfant.forEach(value =>{
                if (value.appellation==='liste_etudiant' && value.etat == 1){
                    this.acces.lecture = true;
                }

                if (value.appellation==='inscrire_etudiant' && value.etat == 1){
                    this.acces.create = true;
                }
                if (value.appellation==="modif_etudiant" && value.etat == 1){
                    this.acces.edit = true;
                }

                if (value.appellation==="supprimer_etudiant" && value.etat == 1){
                    this.acces.delete = true;
                }
            })
        }


    },
    computed: {},
    watch: {},
    mounted: function () {
        this.FetchDataTable();
        this.getListypeFormation();
        this.getListeFiliere();
        setTimeout(()=>{
            this.getModule();
        },750)
    },

};

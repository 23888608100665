import { render, staticRenderFns } from "./ExamenList.vue?vue&type=template&id=be57b620&scoped=true"
import script from "./ExamenList.js?vue&type=script&lang=js&external"
export * from "./ExamenList.js?vue&type=script&lang=js&external"
import style0 from "./ExamenList.vue?vue&type=style&index=0&id=be57b620&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be57b620",
  null
  
)

export default component.exports
import axios from "axios";
import PayementFraisFormation from "../../../components/PayementFraisFormation/PayementFraisFormation.vue";

export default {
    name: 'ListeFraisFormation',
    components: {
        PayementFraisFormation
    },

    data() {
        return {
            BASE_URL: this.$root.BASE_URL,
            dialogModal: false,
            snackBar: {
                color: "grey darken-4",
                icon:'mdi-success',
                position:'top',
                timeout:'',
                title:'',
                text:'',
                visible:false,

            },
            filtre:{
                id_type_formation: '',
                id_filiere:'',
                id_niveau:'',
                numero_matricule: '',
            },
            tab_typeFormation:[],
            tab_filiere:[],
            isDeveloppeur:this.$store.getters['isAccesForDeveloppeur'],
            tab_niveau:[],
            AllData:{frais_formation:[],etudiant:[]},
            sendtoProps:{
                id_paiement:''
            },
            ELEMENT: this.$store.getters['getAllAcces'][0].module_enfant,
            acces:{
                lecture:false,
                create:false,
                edit:false,
                delete:false,
            }

        }
    },
    methods: {
        showFrais(){
            const formulaire = new FormData();
            formulaire.append('data',JSON.stringify(this.filtre))
            const link = (this.filtre.id_niveau >0) ? this.BASE_URL+"/etudiantniveau/getdetailformation/" : this.BASE_URL+"/etudiantniveau/getdetailformationetudiant/";
            axios.post(link,formulaire).then((response)=>{
                if (response.data.error==''){
                    this.AllData = response.data.data;
                }else{
                    this.snackBar = {
                        text: response.data.error =='' ? response.data.message : response.data.error,
                        color: response.data.error =='' ? "success" : "warning" ,
                        visible: true,
                        timeout:'3000'
                    };
                }



            })
        },
        getListypeFormation(){
            axios.get(this.BASE_URL+"/typeformation/getall").then((resultat)=>{
                this.tab_typeFormation = resultat.data;

            })
        },
        getListeFiliere(){
            axios.get(this.BASE_URL+"/filiere/gettoformation/"+this.filtre.id_type_formation).then((resultat)=>{
                this.tab_filiere = resultat.data;
            })
        },

        getListeNiveau(){
            axios.get(this.BASE_URL+"/niveau/getniveauparfiliere/"+this.filtre.id_filiere).then((resultat)=>{
                this.tab_niveau = resultat.data;
            })
        },
        openModal(id,id_etudiant_niveau){
            this.$refs.payement.openModalFilter(id,id_etudiant_niveau);
        }
    },
    computed: {},
    watch: {},
    mounted: function () {
        this.getListypeFormation();
        this.ELEMENT.forEach(value =>{
            if (value.appellation==="etat_paiement_etudiant" && value.etat == 1){
                this.acces.lecture = true;
            }

            if (value.appellation==="reglement_frais_formation" && value.etat == 1){
                this.acces.create = true;
            }

        })
    }
};

import axios from "axios";
import ConfirmationAction from "../../../components/ConfirmationAction/ConfirmationAction.vue";
import HeurePicker from "../../../components/DatePicker/HeurePicker.vue";


export default {
    name: 'EmploiDuTemp',
    components: {
        ConfirmationAction,
        HeurePicker
    },

    data() {
        return {
            BASE_URL: this.$root.BASE_URL,
            dialogModal: false,
            isDeveloppeur: this.$store.getters['isAccesForDeveloppeur'],
            ListJour: [{value: 'Lundi'},
                {value: 'Mardi'},
                {value: 'Mercredi'},
                {value: 'Jeudi'},
                {value: 'Vendredi'},
                {value: 'Samedi'},
                {value: 'Dimanche'},
            ],
            crudForm: {
                id: '',
                libeller_emploi_du_temp: '',
                id_annee_universitaire: 0,
                details: [],
                id_filiere: '',
                id_niveau: '',
            },
            filiere_liste: [],
            niveau_liste: [],
            matiere_liste: [],
            snackBar: {
                color: "grey darken-4",
                icon: 'mdi-success',
                position: 'top',
                timeout: '',
                title: '',
                text: '',
                visible: false,

            },
            headers: [


                {
                    text: 'Type formation',
                    value: 'libeller_emploi_du_temp',
                    class: "text-uppercase text-left",
                    width: '750px'
                },
                {
                    text: 'Niveau',
                    value: 'libeller_niveau',
                    class: "text-uppercase text-left",
                },

                {
                    text: 'Mention',
                    value: 'nom_filieres',
                    class: "text-uppercase text-left",
                },
                {
                    text: 'Type de formation',
                    value: 'libeller_type_formation',
                    class: "text-uppercase text-left",
                },

                {
                    text: '-', value: 'actions', sortable: false, width: "110px",
                    align: 'center',
                    class: "text-center"
                },
            ],
            rules: {
                textRequis: [
                    v => !!v || 'CE CHAMP EST REQUIS',
                ],
                listBox: [val => (val || '').length > 0 || 'CE CHAMP EST REQUIS'],
            },
            DataTable: {
                fullData: [],
                order_by: "id",
                order: "asc",
                per_page: 10,
                last_page: 0,
                page: 1,
                total: 0,
                loading: true,
                recherche_critere: {
                    filtre: '',
                }
            },
            acces: {
                lecture: false,
                create: false,
                edit: false,
                delete: false,
            },
            ELEMENT: this.$store.getters['getAllAcces'][1].module_enfant,
            link_pdf: '',
            dialogModalPrint: false,
        }
    },
    methods: {
        loadEnseignant(index, id_matiere) {
            let matiere = this.matiere_liste.filter((matiere) => matiere.id == id_matiere);
            this.crudForm.details[index].nom_enseignant = matiere[0].nom_enseignant + ' ' + matiere[0].prenom_enseignant;
        },
        LoadMatiere() {
            axios.get(this.BASE_URL + "/matiere/getmatiereparniveau/" + this.crudForm.id_niveau).then((response) => {
                this.matiere_liste = response.data;
            }).catch(error => {
                console.log(error)
            });
        },
        LoadNiveau() {
            axios.get(this.BASE_URL + "/niveau/getniveauparfiliere/" + this.crudForm.id_filiere).then((response) => {
                this.niveau_liste = response.data;
            }).catch(error => {
                console.log(error)
            });
        },
        getAllFiliere() {
            axios.get(this.BASE_URL + "/filiere/getall/").then((response) => {
                this.filiere_liste = response.data;
            }).catch(error => {
                console.log(error)
            });
        },

        FetchDataTable() {
            let formulaire = new FormData();
            formulaire.append('filtre', this.DataTable.recherche_critere.filtre);
            formulaire.append("order", this.DataTable.order);
            formulaire.append("per_page", this.DataTable.per_page);
            formulaire.append("page", this.DataTable.page);

            this.DataTable.loading = true;
            axios.post(this.BASE_URL + "/emploidutemp/fetchdata", formulaire).then((response) => {
                this.DataTable.per_page = parseInt(response.data.per_page);
                this.DataTable.fullData = response.data.data;
                this.DataTable.page = parseInt(response.data.page);
                this.DataTable.loading = false;
                this.DataTable.last_page = parseInt(response.data.last_page);

            });
        },

        editRow(data) {
            axios.post(this.BASE_URL + "/emploidutemp/get/" + data.id).then((response) => {
                this.crudForm = response.data[0];
                this.LoadNiveau();
                this.LoadMatiere();
                this.openModal();
            }).catch(error => {
                console.log(error)
            });
        },
        showPrint(data) {
            console.log(data.id)
            axios.get(this.BASE_URL + '/emploidutemp/imprimer/'+data.id).then((response) => {
                this.dialogModalPrint = true;
                this.link_pdf = response.data.pdfUrl;
            })
        },

        closeModalPrint() {
            this.link_pdf = '';
            this.dialogModalPrint = false;
        },

        DeleteRow(data) {
            var lien = this.BASE_URL + "/emploidutemp/delete/" + data.id;
            console.log(lien)
            this.$refs.confirmation.open("Confirmation", "Voulez vous vraiment supprimer cette ligne?", "100", lien)
        },
        InsertElement() {

            this.crudForm = {
                id_filiere: '',
                id_niveau: '',
                id: '',
                libeller_emploi_du_temp: '',
                id_annee_universitaire: 0,
                etat_sup_annee_uv: 0,
                details: [{
                    id: 0,
                    id_emploi_du_temp: 0,
                    jour: '',
                    heure_debut: '',
                    heure_fin: '',
                    salle: '',
                    id_matiere: '',
                    nom_enseignant: '',
                }],
            };
            this.openModal();
        },
        pushElement() {
            this.crudForm.details.push({
                id: 0,
                id_emploi_du_temp: 0,
                jour: '',
                heure_debut: '',
                heure_fin: '',
                salle: '',
                id_matiere: '',
                nom_enseignant: '',
            })
        },
        spliceElement(index) {
            this.crudForm.details.splice(index, 1);
        },


        saveData() {

            const link = this.crudForm.id == 0 ? this.BASE_URL + '/emploidutemp/create' :
                this.BASE_URL + '/emploidutemp/update';
            const data = JSON.stringify(this.crudForm);
            let formulaire = new FormData();
            formulaire.append('data', data)
            axios.post(link, formulaire, {
                'Content-type': 'application/json'
            }).then((response) => {
                console.log(response);
                if (response.data.error == '') {
                    this.closeModal();
                    this.FetchDataTable();
                }

                this.snackBar = {
                    text: response.data.error == '' ? response.data.message : response.data.error,
                    color: response.data.error == '' ? "success" : "warning",
                    visible: true,
                    timeout: '5000'
                };
            }).catch(error => {
                console.log('erreur', error);

            });
        },

        openModal() {
            this.dialogModal = true;
        },
        closeModal() {
            this.dialogModal = false;
        },


    },
    computed: {},
    watch: {},


    mounted: function () {
        this.FetchDataTable();
        this.getAllFiliere();
        this.ELEMENT.forEach(value => {
            if (value.appellation === "lsite_type_formation" && value.etat == 1) {
                this.acces.lecture = true;
            }

            if (value.appellation === "ajout_type_formation" && value.etat == 1) {
                this.acces.create = true;
            }
            if (value.appellation === "modifier_type_formation" && value.etat == 1) {
                this.acces.edit = true;
            }

            if (value.appellation === "supprimer_type_formation" && value.etat == 1) {
                this.acces.delete = true;
            }
        })
    }
};

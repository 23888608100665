<template>

    <v-menu
            :ref="refElement"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                    dense
                    outlined
                    clearable
                    hide-details
                    v-model="dateFormatted"
                    :label="label"
                    hint="DD/MM/YYYY"
                    :class="class_name"
                    persistent-hint
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    @blur="date = parseDate(dateFormatted)"
                    v-on="on"
                    :name="name"
            ></v-text-field>
            <!-- readonly -->
        </template>
        <v-date-picker
                v-model="date"
                no-title
                @input="menu1 = false"
        ></v-date-picker>
    </v-menu>
</template>

<script>export default {
    props: {
        is_default:{
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: "",
            required: false
        },
        label: {
            type: String,
            default: "",
            required: false
        },
        refElement: {
            type: String,
            default: "",
            required: false
        },
        name: {
            type: String,
            default: "",
            required: false
        },
        class_name:{
            type:String,
            default:'margin-3'
        },
    },
    data() {
        return {
            menu1: false,
            menu2: false,
            date: "",
            dateFormatted: ''
        }
    },
    computed: {
        computedDateFormatted() {
            return this.formatDate(this.date)
        },
    },

    watch: {
        date(val) {
            val;
            this.dateFormatted = this.formatDate(this.date);
            this.$emit("input", this.dateFormatted);
        },
        value(val) {
            this.date = this.parseDate(val);
        }
    },

    methods: {
        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate(date) {
            if (!date) return null

            const [day, month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },

        setDefaultDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');

            this.date = `${year}-${month}-${day}`;
            this.dateFormatted = this.formatDate(this.date);
        }
    },
    mounted: function () {
        if (this.is_default){
            this.setDefaultDate();
        }

    }
}
</script>

import { render, staticRenderFns } from "./GroupeUtilisateur.vue?vue&type=template&id=ddfa46b4&scoped=true"
import script from "./GroupeUtilisateur.js?vue&type=script&lang=js&external"
export * from "./GroupeUtilisateur.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddfa46b4",
  null
  
)

export default component.exports
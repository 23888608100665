<template >
    <!--<v-app>
        <v-main>
            <v-layout row justify-center align-center class="center-vertically">
                <v-col cols="12" md="6" lg="6">
                    <v-card class="card-shadow mt-4">
                        <v-card-title class="text-h6" style="background-color: #4caf50; color: white">
                            Se connecter
                        </v-card-title>
                        <v-card-text class="pt-4 mb-4">
                            <v-form lazy-validation
                                    ref="form"
                                    id="formulaire"
                                    v-on:submit.prevent="submit">

                                <v-text-field label="Login" v-model="crudForm.login_utilisateur"></v-text-field>
                                <v-text-field label="Mot de passe" type="password" v-model="crudForm.mdp_utilisateur"></v-text-field>
                                <v-btn color="primary" type="submit" class="mb-5">Se connecter</v-btn>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-layout>
        </v-main>


        <v-snackbar
                v-model="snackBar.visible"

                :color="snackBar.color"
                :multi-line="snackBar.mode === 'multi-line'"
                :timeout="snackBar.timeout"
                :top="snackBar.position === 'top'">
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackBar.icon }}</v-icon>
                <v-layout column>
                    <div>
                        <strong>{{ snackBar.title }}</strong>
                    </div>
                    <div>{{ snackBar.text }}</div>
                </v-layout>
            </v-layout>
            <v-btn v-if="snackBar.timeout === 0" @click="snackBar.visible = false">
                <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>

    </v-app>-->
    <!--    <div class="content" style="width: 100%; height: 100vh">-->
    <!--        <div class="wrapper">-->
    <!--            <v-form lazy-validation-->
    <!--                    ref="form"-->
    <!--                    id="formulaire"-->
    <!--                    v-on:submit.prevent="submit">-->

    <!--                <h1>Login</h1>-->
    <!--                <div class="input-box">-->
    <!--                    <input type="text" v-model="crudForm.login_utilisateur" placeholder="Username" required>-->
    <!--                    <i class='bx bxs-user'></i>-->
    <!--                </div>-->
    <!--                <div class="input-box">-->
    <!--                    <input type="password" v-model="crudForm.mdp_utilisateur" placeholder="Password" required>-->
    <!--                    <i class='bx bxs-lock-alt' ></i>-->
    <!--                </div>-->

    <!--                <button type="submit" class="btn">Se connecter</button>-->
    <!--            </v-form>-->
    <!--        </div>-->
    <!--    </div>-->
    <v-container>
        <div class="content"
             style="width: 100%; min-height: 100vh; display: flex; justify-content: center; align-items: center;">
            <div class="wrapper" style="width: 100%; max-width: 100%;">
                <v-row align="center" justify="center">
                    <v-col cols="12" sm="10">
                        <v-card class="elevation-6 border-card" style="padding: 50px !important;">
                            <v-window v-model="step">
                                <v-window-item :value="1">
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-form lazy-validation
                                                    ref="form"
                                                    id="formulaire"
                                                    v-on:submit.prevent="submit">
                                                <v-card-text>
                                                    <h4 class="text-center">Login in to Your Account</h4>
                                                    <br>
                                                    <v-row align="center" justify="center">
                                                        <v-col cols="12" sm="8">
                                                            <v-text-field
                                                                label="Login"
                                                                outlined
                                                                required
                                                                dense
                                                                color="blue"
                                                                v-model="crudForm.login_utilisateur"
                                                                autocomplete="false"
                                                                class="mt-16"
                                                            />
                                                            <v-text-field
                                                                label="Password"
                                                                outlined
                                                                dense
                                                                color="blue"
                                                                v-model="crudForm.mdp_utilisateur"
                                                                autocomplete="false"
                                                                type="password"
                                                            />
                                                            <v-btn type="submit" color="blue" dark block tile>Log in</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>

                                            </v-form>

                                        </v-col>
                                        <v-col cols="12" md="6" class="blue rounded-bl-xl">
                                            <div class="my-5">
                                                <v-img :src="LOGO"></v-img>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-window-item>
                                <v-window-item :value="2">
                                    <v-row>
                                        <v-col cols="12" md="6" class="blue rounded-br-xl">
                                            <div style="text-align: center; padding: 180px 0;">
                                                <v-card-text class="white--text">
                                                    <h3 class="text-center">Already Signed up?</h3>
                                                    <h6 class="text-center">
                                                        Log in to your account so you can continue building
                                                        <br>and editing your onboarding flows
                                                    </h6>
                                                </v-card-text>
                                                <div class="text-center">
                                                    <v-btn tile outlined dark @click="step--">Log in</v-btn>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-card-text class="mt-12">
                                                <h4 class="text-center">Sign Up for an Account</h4>
                                                <h6 class="text-center grey--text">
                                                    Let's get you all set up so you can start creating
                                                    <br>your first onboarding experience
                                                </h6>
                                                <v-row align="center" justify="center">
                                                    <v-col cols="12" sm="8">
                                                        <v-row>
                                                            <v-col cols="12" sm="6">
                                                                <v-text-field
                                                                    label="First Name"
                                                                    outlined
                                                                    dense
                                                                    color="blue"
                                                                    autocomplete="false"
                                                                    class="mt-4"
                                                                />
                                                            </v-col>
                                                            <v-col cols="12" sm="6">
                                                                <v-text-field
                                                                    label="Last Name"
                                                                    outlined
                                                                    dense
                                                                    color="blue"
                                                                    autocomplete="false"
                                                                    class="mt-4"
                                                                />
                                                            </v-col>
                                                        </v-row>
                                                        <v-text-field
                                                            label="Email"
                                                            outlined
                                                            dense
                                                            color="blue"
                                                            autocomplete="false"
                                                        />
                                                        <v-text-field
                                                            label="Password"
                                                            outlined
                                                            dense
                                                            color="blue"
                                                            autocomplete="false"
                                                            type="password"
                                                        />
                                                        <v-row>
                                                            <v-col cols="12" sm="7">
                                                                <v-checkbox
                                                                    label="I Accept AAE"
                                                                    class="mt-n1"
                                                                    color="blue"
                                                                ></v-checkbox>
                                                            </v-col>
                                                            <v-col cols="12" sm="5">
                                                                <span class="caption blue--text ml-n4">Terms & Conditions</span>
                                                            </v-col>
                                                        </v-row>
                                                        <v-btn color="blue" dark block tile>Sign up</v-btn>
                                                        <h5 class="text-center grey--text mt-4 mb-3">Or Sign up
                                                            using</h5>
                                                        <div
                                                            class="d-flex justify-space-between align-center mx-10 mb-11">
                                                            <v-btn depressed outlined color="grey">
                                                                <v-icon color="red">fab fa-google</v-icon>
                                                            </v-btn>
                                                            <v-btn depressed outlined color="grey">
                                                                <v-icon color="blue">fab fa-facebook-f</v-icon>
                                                            </v-btn>
                                                            <v-btn depressed outlined color="grey">
                                                                <v-icon color="light-blue lighten-3">fab fa-twitter
                                                                </v-icon>
                                                            </v-btn>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-window-item>
                            </v-window>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </div>

        <v-snackbar
            v-model="snackBar.visible"

            :color="snackBar.color"
            :multi-line="snackBar.mode === 'multi-line'"
            :timeout="snackBar.timeout"
            :top="snackBar.position === 'top'">
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackBar.icon }}</v-icon>
                <v-layout column>
                    <div>
                        <strong>{{ snackBar.title }}</strong>
                    </div>
                    <div>{{ snackBar.text }}</div>
                </v-layout>
            </v-layout>
            <v-btn v-if="snackBar.timeout === 0" @click="snackBar.visible = false">
                <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>

</template>

<script>

import Vue from 'vue'
import axiosInstance from "@/AxiosInstance";

export default {
    name: 'SingIn',

    components: {},

    data() {
        return {
            LOGO: this.BASE_URL + '/assets/images/logo/1.png',
            step: 1,
            showMenu: true,
            base_url: this.BASE_URL,
            BASE_URL: this.$root.BASE_URL,
            crudForm: {
                id: '',
                login_utilisateur: '',
                mdp_utilisateur: '',
            },
            snackBar: {
                color: "grey darken-4",
                icon: 'mdi-success',
                position: 'top',
                timeout: '',
                title: '',
                text: '',
                visible: false,

            },
        }
    },
    methods: {
        submit() {
            var link = Vue.BASE_URL + "/utilisateur/loginactionjwt";
            const data = JSON.stringify(this.crudForm);
            let formulaire = new FormData();
            formulaire.append('data', data)
            axiosInstance.post(link, formulaire).then((response) => {
                if (response.data.error === "") {
                    console.log(response.data.message);
                    localStorage.setItem("token", response.data.token);
                    location.reload();
                } else {
                    console.log("Erreur");
                }

                this.snackBar = {
                    text: response.data.error == '' ? response.data.message : response.data.error,
                    color: response.data.error == '' ? "success" : "warning",
                    visible: true,
                    timeout: '5000'
                };
            });
        },
    }
};
</script>
<style scoped>

.border-card {
    border: 2px solid rgba(64, 246, 107, 0.26); /* Couleur bleue par défaut de Vuetify */
    /* Vous pouvez ajuster l'épaisseur de la bordure et la couleur selon vos besoins */
}
/* .card-shadow {
     box-shadow: 0 4px 8px -2px rgba(0, 128, 0, 0.2), -8px 0 8px -2px rgba(0, 128, 0, 0.2) !important;
 }

 nav a.router-link-exact-active {
     color: #42b983;
 }

 .card-shadow {
     box-shadow: 0 4px 8px -2px rgba(0, 128, 0, 0.2), -8px 0 8px -2px rgba(0, 128, 0, 0.2) !important;
     transition: box-shadow 0.3s; !* Ajoute une transition en douceur *!
 }

 .card-shadow:hover {
     box-shadow: 0 4px 8px -2px rgba(0, 128, 0, 0.5), -8px 0 8px -2px rgba(0, 128, 0, 0.5) !important;
 }

 .menu-modal {
     display: flex;
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: rgba(0, 0, 0, 0.5);
     z-index: 2;
     align-items: center;
     justify-content: center;
 }

 .menu-content {
     background: white;
     padding: 20px;
     border-radius: 0;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
     width: 100%;
     height: 100%;
     max-width: none;
     max-height: none;
 }

 .main-content {
     z-index: 1;
 }

 .modal-fade-enter-active, .modal-fade-leave-active {
     transition: opacity 0.5s;
 }

 .modal-fade-enter, .modal-fade-leave-to {
     opacity: 0;
 }

 .center-vertically {
     display: flex;
     justify-content: center;
     align-items: center;
     height: 100vh; !* Utilise la hauteur de la fenêtre pour centrer *!
 }*/

/**{
    margin: 0 !important;
    padding: 0 !important;
    box-sizing: border-box !important;
    font-family: "Poppins", sans-serif !important;
}
.content{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    min-height: 100vh !important;
    background: url('/public/assets/img/bg.jpg') no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
}
.wrapper{
    width: 420px;
    height: 450px !important;
    background: transparent !important;
    border: 2px solid rgba(255, 255, 255, .2) !important;
    backdrop-filter: blur(9px) !important;
    color: #fff !important;
    border-radius: 12px !important;
    padding: 30px 40px !important;
}
.wrapper h1{
    font-size: 36px !important;
    text-align: center !important;
}
.wrapper .input-box{
    position: relative !important;
    width: 100% !important;
    height: 50px !important;

    margin: 30px 0 !important;
}
.input-box input{
    width: 100% !important;
    height: 100% !important;
    background: transparent !important;
    border: none !important;
    outline: none !important;
    border: 2px solid rgba(255, 255, 255, .2) !important;
    border-radius: 40px !important;
    font-size: 16px !important;
    color: #fff !important;
    padding: 20px 45px 20px 20px !important;
}
.input-box input::placeholder{
    color: #fff !important;
}
.input-box i{
    position: absolute !important;
    right: 20px !important;
    top: 30% !important;
    transform: translate(-50%) !important;
    font-size: 20px !important;

}
.wrapper .remember-forgot{
    display: flex !important;
    justify-content: space-between !important;
    font-size: 14.5px !important;
    margin: -15px 0 15px !important;
}
.remember-forgot label input{
    accent-color: #fff !important;
    margin-right: 3px !important;

}
.remember-forgot a{
    color: #fff !important;
    text-decoration: none !important;

}
.remember-forgot a:hover{
    text-decoration: underline !important;
}
.wrapper .btn{
    width: 100% !important;
    height: 45px !important;
    background: #fff !important;
    border: none !important;
    outline: none !important;
    border-radius: 40px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1) !important;
    cursor: pointer !important;
    font-size: 16px !important;
    color: #333 !important;
    font-weight: 600 !important;
}
.wrapper .register-link{
    font-size: 14.5px !important;
    text-align: center !important;
    margin: 20px 0 15px !important;

}
.register-link p a{
    color: #fff !important;
    text-decoration: none !important;
    font-weight: 600 !important;
}
.register-link p a:hover{
    text-decoration: underline !important;
}*/

.v-application .rounded-bl-xl {
    border-bottom-left-radius: 300px !important;
}

.v-application .rounded-br-xl {
    border-bottom-right-radius: 300px !important;
}
</style>
